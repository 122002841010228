import { FormHelperText } from '@mui/material';
import { getIn, useFormikContext } from 'formik';

export interface MultiFieldErrorProps {
  fields: Array<string>;
}

export function MultiFieldError<T>({ fields }: MultiFieldErrorProps) {
  const formik = useFormikContext<T>();
  const messages = fields
    .map((field) => getIn(formik.errors, field))
    .filter((x) => x);

  if (messages.length === 0) {
    return null;
  }

  return (
    <FormHelperText error sx={{ paddingTop: 0, marginLeft: '14px' }}>
      {messages.join(', ')}
    </FormHelperText>
  );
}
