import { useMutation, useQueryClient } from 'react-query';

import { ScheduleConfigService } from 'openapi';
import { HolidayWithShifts } from 'pages/scheduleConfig/Holidays';

export const useCreateHolidayMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (holiday: HolidayWithShifts) => {
      const newHoliday =
        await ScheduleConfigService.scheduleConfigServiceCreateHoliday(holiday);
      await ScheduleConfigService.scheduleConfigServiceUpdateHolidayShifts(
        newHoliday.id,
        holiday.shifts,
      );
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['holidays'] });
      queryClient.refetchQueries({ queryKey: ['holidaysWithShifts'] });
    },
  });
};
