import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  Popper,
  Typography,
  useTheme,
} from '@mui/material';
import { PrintOnly } from 'components/atoms/PrintOnly';
import { ScreenOnly } from 'components/atoms/ScreenOnly';
import { addDays, addMonths, addYears, format } from 'date-fns';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { SchedulePeriod } from 'util/enum';
import {
  AssignmentHistoryStart,
  monthLimits,
  monthSpan,
  weekSpan,
} from 'util/date';

export interface PeriodHeaderProps {
  period: SchedulePeriod;
  startDate: Date;
  onSelectDate: (date: Date) => void;
}

const PeriodInteraction = {
  [SchedulePeriod.Weekly]: {
    increment: (date: Date) => addDays(date, 7),
    decrement: (date: Date) => addDays(date, -7),
    format: (date: Date) => (
      <>
        <Typography variant="h2">{format(date, 'MMMM y')}</Typography>
        <Typography variant="body1">{`${format(date, 'MMM d')} - ${format(
          addDays(date, 6),
          'MMM d',
        )}`}</Typography>
      </>
    ),
  },
  [SchedulePeriod.Monthly]: {
    increment: (date: Date) => addMonths(date, 1),
    decrement: (date: Date) => addMonths(date, -1),
    format: (date: Date) => (
      <Typography variant="h2">{format(date, 'MMMM y')}</Typography>
    ),
  },
  [SchedulePeriod.Annually]: {
    increment: (date: Date) => addYears(date, 1),
    decrement: (date: Date) => addYears(date, -1),
    format: (date: Date) => (
      <Typography variant="h2">{format(date, 'y')}</Typography>
    ),
  },
};

export const PeriodHeader: React.FC<PeriodHeaderProps> = ({
  period,
  startDate,
  onSelectDate,
}) => {
  const theme = useTheme();
  const { increment, decrement, format } = PeriodInteraction[period];
  const [popperOpen, setPopperOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [displayDate, setDisplayDate] = useState(startDate);

  const [firstOfMonth] = monthLimits(startDate);
  const [fromDate] =
    period === SchedulePeriod.Weekly
      ? weekSpan(startDate)
      : monthSpan(startDate);
  const headerDate = period === SchedulePeriod.Weekly ? fromDate : firstOfMonth;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPopperOpen((previousOpen) => !previousOpen);
  };

  return (
    <>
      <ScreenOnly>
        <Grid
          container
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: theme.spacing(8) }}
          aria-describedby="period-header"
          pb={2}>
          <Box display="flex" justifyContent="right" flexGrow={1}>
            <IconButton
              onClick={() => onSelectDate(decrement(headerDate))}
              sx={{ height: 'fit-content' }}>
              <NavigateBefore />
            </IconButton>
          </Box>
          <Grid item textAlign="center" sx={{ width: 250 }}>
            <div onClick={handleClick} style={{ cursor: 'pointer' }}>
              {format(headerDate)}
            </div>
          </Grid>
          <Box display="flex" justifyContent="left" flexGrow={1}>
            <IconButton
              onClick={() => onSelectDate(increment(headerDate))}
              sx={{ height: 'fit-content' }}>
              <NavigateNext />
            </IconButton>
          </Box>
        </Grid>
        <Popper id={'period-header'} open={popperOpen} anchorEl={anchorEl}>
          <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
            <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
              <StaticDatePicker
                value={displayDate}
                minDate={AssignmentHistoryStart}
                maxDate={addMonths(new Date(), 6)}
                views={['year', 'month']}
                openTo={'month'}
                onChange={(date) => {
                  if (date) setDisplayDate(date);
                }}
                onAccept={(date) => {
                  if (date) onSelectDate(date);
                  setPopperOpen(false);
                }}
                slotProps={{
                  toolbar: {
                    hidden: true,
                  },
                }}
              />
            </Box>
          </ClickAwayListener>
        </Popper>
      </ScreenOnly>
      <PrintOnly>
        <Box textAlign="center" pb={1}>
          {format(headerDate)}
        </Box>
      </PrintOnly>
    </>
  );
};
