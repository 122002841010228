import { ShiftPointsSubform, ShiftPointsSchema } from './ShiftPointsSubform';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Shift } from 'openapi';
import { useSnackbar } from 'notistack';
import { Button, Grid, Stack, useTheme } from '@mui/material';
import { addDays } from 'date-fns';
import { useUnpublishedAssignmentMutation } from 'hooks/useUnpublishedAssignmentMutation';
import { createAssignmentsFromShift } from 'util/shiftAssignments';

const AddShiftSchema = Yup.object()
  .shape({
    shiftId: Yup.number(),
  })
  .concat(ShiftPointsSchema);

export interface AddShiftFormProps {
  monday: Date;
  shift: Shift;
  onClose: () => void;
}

export const AddShiftForm: React.FC<AddShiftFormProps> = ({
  monday,
  shift,
  onClose,
}: AddShiftFormProps) => {
  const theme = useTheme();
  const snackbar = useSnackbar();

  const shiftMutation = useUnpublishedAssignmentMutation();

  return (
    <Formik
      initialValues={{ shift }}
      enableReinitialize
      onSubmit={async (values) => {
        try {
          const newAssignments = createAssignmentsFromShift(
            monday,
            addDays(monday, 6),
            values.shift,
          );
          await shiftMutation.mutateAsync(newAssignments);
          onClose();
        } catch {
          snackbar.enqueueSnackbar('Failed to add shift', {
            variant: 'error',
          });
        }
      }}
      validationSchema={AddShiftSchema}>
      {(formik) => (
        <Form noValidate>
          <Grid container columnSpacing={1} rowSpacing={2} alignItems="center">
            <ShiftPointsSubform />
          </Grid>
          <Stack pt={4} direction="row" spacing={1} justifyContent="right">
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ width: theme.spacing(10) }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => formik.submitForm()}
              sx={{ width: theme.spacing(10) }}>
              Confirm
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
