import * as Yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    phoneNumberIsh(): StringSchema;
  }
}

Yup.addMethod<Yup.StringSchema>(Yup.string, 'phoneNumberIsh', function () {
  return this.test(
    'is-phone-number-ish',
    'Please enter a valid 10-digit phone number',
    (value) =>
      value === undefined ||
      value === null ||
      value?.replace(/[().\- ]+/g, '').match(/^[0  -9]{10}$/)?.length === 1,
  );
});
