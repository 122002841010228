import {
  Button,
  FormLabel,
  Grid,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Loading } from 'components/atoms/Loading';
import {
  addWeeks,
  addYears,
  format,
  isDate,
  isSunday,
  nextSunday,
} from 'date-fns';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { Checkbox, Select } from 'formik-mui';
import { DatePicker, DatePickerProps } from 'formik-mui-x-date-pickers';
import { usePartnersQuery } from 'hooks/usePartnersQuery';
import { ScheduleConfigService, VacationRequest } from 'openapi';
import { useAuth } from 'providers/AuthProvider';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { dateToString } from 'util/date';
import * as Yup from 'yup';

interface VacationRequestValues {
  partnerId: string;
  vacationType: string;
  startDate: string;
  endDate: string;
  priority: boolean;
}

const emptyFormValues: VacationRequestValues = {
  partnerId: '',
  vacationType: '',
  startDate: '',
  endDate: '',
  priority: false,
};

export interface VacationRequestFormProps {
  onSubmit: (request: VacationRequest) => void;
  onCancel?: () => void;
}

const useStartEndDateLink = () => {
  const { values, setValues } = useFormikContext<VacationRequestValues>();
  const [dates, setDates] = useState({
    startDate: '',
    endDate: '',
  });
  if (isDate(values.startDate) && values.startDate !== dates.startDate) {
    const startDate = new Date(values.startDate);
    const oneWeekLater = addWeeks(startDate, 1);
    const endDate = isSunday(oneWeekLater)
      ? oneWeekLater
      : nextSunday(oneWeekLater);
    const endDateString = format(endDate, 'MM/dd/yyyy');
    setDates({
      startDate: values.startDate,
      endDate: endDateString,
    });
    setValues({
      ...values,
      endDate: endDateString,
    });
  } else if (isDate(values.endDate) && values.endDate !== dates.endDate) {
    setDates({
      startDate: values.startDate,
      endDate: values.endDate,
    });
    setValues({
      ...values,
      endDate: values.endDate,
    });
  }
  return dates;
};

const LinkedEndDatePicker: React.FC<DatePickerProps> = (props) => {
  const { endDate } = useStartEndDateLink();
  return <DatePicker value={endDate} {...props} />;
};

export const VacationRequestForm: React.FC<VacationRequestFormProps> = ({
  onSubmit,
  onCancel,
}) => {
  const { isAdmin, profile } = useAuth();

  const { isLoading: partnersLoading, data: partners } = usePartnersQuery();
  const { isLoading: vacationsLoading, data: vacationTypes } = useQuery(
    ['vacationTypes'],
    () => ScheduleConfigService.scheduleConfigServiceGetVacationTypes(),
  );

  const { partnerOptions, vacationOptions } = useMemo(() => {
    return {
      partnerOptions:
        partners
          ?.sort((a, b) => a.initials?.localeCompare(b.initials ?? '') ?? 0)
          .map((partner) => (
            <MenuItem key={partner.id} value={partner.id}>
              {partner.initials}
            </MenuItem>
          )) ?? [],
      vacationOptions:
        vacationTypes?.map((vacationType) => (
          <MenuItem key={vacationType.id} value={vacationType.id}>
            {vacationType.symbol}
          </MenuItem>
        )) ?? [],
    };
  }, [partners, vacationTypes]);

  const theme = useTheme();

  if (partnersLoading || vacationsLoading) {
    return <Loading />;
  } else if (!partners || !vacationTypes) {
    return <Typography>No data was retrieved.</Typography>;
  }

  return (
    <Formik
      initialValues={
        {
          ...emptyFormValues,
          partnerId: (profile?.id ?? '').toString(),
        } as VacationRequestValues
      }
      validationSchema={Yup.object().shape({
        partnerId: Yup.number().required('Partner is a required field'),
        vacationType: Yup.number().required('Vacation is a required field'),
        startDate: Yup.date()
          .min(new Date(), 'Start date must be in the future')
          .max(
            addYears(new Date(), 2),
            'Start date must be within the next two years',
          )
          .required('A start date must be selected')
          .nullable()
          .default(undefined),
        endDate: Yup.date()
          .min(Yup.ref('startDate'), 'End date must be after the start date')
          .max(
            addYears(new Date(), 2),
            'End date must be within the next two years',
          )
          .required('An end date must be selected')
          .nullable()
          .default(undefined),
      })}
      onSubmit={async (values) => {
        const requestWithTime: VacationRequest = {
          id: 0,
          partnerId: parseInt(values.partnerId),
          vacationType: parseInt(values.vacationType),
          startDate: values.startDate,
          endDate: values.endDate,
          approved: null,
          priority: values.priority ? 1 : 0,
          requestDate: dateToString(new Date()),
          reason: '',
        };
        onSubmit(requestWithTime);
      }}>
      {() => (
        <Form noValidate>
          <Grid
            container
            columnSpacing={1}
            rowSpacing={2}
            alignItems="center"
            sx={{
              maxWidth: theme.spacing(60),
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
            {isAdmin ? (
              <Grid container item alignItems="center">
                <Grid item xs={12} sm={4} textAlign="center">
                  <FormLabel>Partner</FormLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Field name="partnerId" component={Select}>
                    {partnerOptions}
                  </Field>
                </Grid>
              </Grid>
            ) : (
              <Field name="partnerId" type="hidden" />
            )}
            <Grid container item alignItems="center">
              <Grid item xs={12} sm={4} textAlign="center">
                <FormLabel>Type</FormLabel>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field name="vacationType" component={Select}>
                  {vacationOptions}
                </Field>
              </Grid>
              <Grid item xs={12} sm={2} textAlign="center">
                <FormLabel>Priority</FormLabel>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Field
                  type="checkbox"
                  label="Priority"
                  name="priority"
                  component={Checkbox}
                />
              </Grid>
            </Grid>
            <Grid container item alignItems="center">
              <Grid item xs={12} sm={4} textAlign="center">
                <FormLabel>Start Date</FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  name="startDate"
                  component={DatePicker}
                  label="Start Date"
                />
              </Grid>
            </Grid>
            <Grid container item alignItems="center">
              <Grid item xs={12} sm={4} textAlign="center">
                <FormLabel>End Date</FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  name="endDate"
                  component={LinkedEndDatePicker}
                  label="End Date"
                />
              </Grid>
            </Grid>
            <Stack direction="row" pt={4} spacing={2} marginLeft="auto">
              <Button
                variant="outlined"
                onClick={onCancel}
                sx={{ width: theme.spacing(10) }}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{ width: theme.spacing(10) }}>
                Create
              </Button>
            </Stack>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
