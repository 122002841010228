import { createContext, ReactNode, useContext, useState } from 'react';
import { useAuth } from './AuthProvider';

export type EditState = {
  isInEditState: boolean;
  startOfMonth?: Date;
  endOfMonth?: Date;
};

export type EditStateContextType = {
  editState: EditState;
  highlightedPartner?: number;
  setEditState: (
    isInEditState: boolean,
    startOfMonth?: Date,
    endOfMonth?: Date,
  ) => void;
  setHighlightedPartner: (id?: number) => void;
  clearEditState: () => void;
};

const defaultSetEditState = () => {
  throw new Error('No provider was found');
};

const defaultSetHighlightedPartner = () => {
  throw new Error('No provider was found');
};

const defaultClearEditState = () => {
  throw new Error('No provider was found');
};

const EditStateContext = createContext<EditStateContextType>({
  editState: {
    isInEditState: false,
  },
  setEditState: defaultSetEditState,
  setHighlightedPartner: defaultSetHighlightedPartner,
  clearEditState: defaultClearEditState,
});

export const useEditState = () => useContext(EditStateContext);

export const EditStateProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { profile } = useAuth();
  const [editState, setEditState] = useState<EditState>({
    isInEditState: false,
  });
  const [highlightedPartner, setHighlightedPartner] = useState<
    number | undefined
  >(profile?.id);

  return (
    <EditStateContext.Provider
      value={{
        editState,
        highlightedPartner,
        setEditState: (
          isInEditState: boolean,
          startOfMonth?: Date,
          endOfMonth?: Date,
        ) => {
          if (!isInEditState) {
            setHighlightedPartner(profile?.id);
          }
          setEditState({
            isInEditState,
            startOfMonth,
            endOfMonth,
          });
        },
        setHighlightedPartner: setHighlightedPartner,
        clearEditState: () => {
          setHighlightedPartner(undefined);
          setEditState({
            isInEditState: false,
          });
        },
      }}>
      {children}
    </EditStateContext.Provider>
  );
};
