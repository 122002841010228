import { Cancel, CheckCircle, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useModal } from 'react-modal-hook';

export interface InlineFormActionsProps {
  isViewOnly: boolean;
  setViewOnly: (isViewOnly: boolean) => void;
}

export const InlineFormActions: React.FC<InlineFormActionsProps> = ({
  isViewOnly,
  setViewOnly,
}) => {
  const theme = useTheme();
  const [showModal, hideModal] = useModal(() => (
    <Dialog open={true} onClose={hideModal}>
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%', width: '100%' }}>
        <Box p={4}>
          <Typography variant="body1">
            If you reset the form you will lose your current progress. Would you
            like to reset the form?
          </Typography>
          <Box p={2} />
          <Stack direction="row" spacing={2} justifyContent="right">
            <Button
              variant="outlined"
              onClick={hideModal}
              sx={{ width: theme.spacing(10) }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={resetForm}
              sx={{ width: theme.spacing(10) }}>
              Okay
            </Button>
          </Stack>
        </Box>
      </Grid>
    </Dialog>
  ));

  const formik = useFormikContext();
  const resetForm = () => {
    setViewOnly(true);
    formik.resetForm();
    hideModal();
  };

  const confirmReset = () => {
    if (formik.dirty) {
      showModal();
    } else {
      resetForm();
    }
  };

  const hasErrors = Object.keys(formik.errors).length > 0;

  return (
    <Stack pb={2} direction="row" spacing={1} justifyContent="right">
      {isViewOnly ? (
        <IconButton color="primary" onClick={() => setViewOnly(false)}>
          <Edit />
        </IconButton>
      ) : (
        <>
          <Button sx={{ display: 'none' }} type="submit">
            Submit
          </Button>
          <IconButton color="primary" onClick={confirmReset}>
            <Cancel />
          </IconButton>
          <IconButton
            color="primary"
            disabled={hasErrors}
            onClick={() => formik.submitForm()}>
            <CheckCircle />
          </IconButton>
        </>
      )}
    </Stack>
  );
};
