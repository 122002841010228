import { useMutation, useQueryClient } from 'react-query';

import { ScheduleService, UnpublishedAssignment } from 'openapi';

export const useUnpublishedAssignmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (assignments: UnpublishedAssignment[]) => {
      return ScheduleService.scheduleServiceUpdateUnpublishedSchedule(
        assignments,
      );
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['schedule'] });
    },
  });
};
