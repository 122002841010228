import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Navigate } from 'react-router-dom';

import { useAuth } from 'providers/AuthProvider';
import { LoginForm } from 'components/forms/LoginForm';

// Adapted from berrydashboard.io
export const Login: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{ width: '100%' }}>
      <Typography
        color={theme.palette.secondary.main}
        gutterBottom
        variant={matchDownSM ? 'h3' : 'h2'}>
        Hi, welcome back!
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Sign in with email address</Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <LoginForm />
      </Box>
    </Stack>
  );
};
