import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { Loading } from 'components/atoms/Loading';
import { useParnterShiftsConfigMutation } from 'hooks/usePartnerShiftsConfigMutation';
import { useShiftsQuery } from 'hooks/useShiftsQuery';
import { useSnackbar } from 'notistack';
import { ReportsService } from 'openapi';
import { ChangeEvent } from 'react';
import { useQuery } from 'react-query';

interface ConfigParnterShiftsProps {
  hideModal: () => void;
}

export const ConfigParnterShifts: React.FC<ConfigParnterShiftsProps> = ({
  hideModal,
}) => {
  const theme = useTheme();
  const snackbar = useSnackbar();

  const { data: shifts, isLoading: shiftsLoading } = useShiftsQuery();
  const { data: shiftsConfig, isLoading: configLoading } = useQuery(
    ['partnerShiftsConfig'],
    async () => ReportsService.reportsServiceGetShiftTotalsConfig(),
  );
  const partnerShiftsConfigMutation = useParnterShiftsConfigMutation();

  const onCheckInclude = async (
    event: ChangeEvent<HTMLInputElement>,
    shiftId: number,
  ) => {
    if (!shiftsConfig) {
      return;
    }
    try {
      if (event.target.checked) {
        if (!shiftsConfig.displayedShifts.includes(shiftId)) {
          const newConfig = shiftsConfig.displayedShifts
            .concat(shiftId)
            .sort((a, b) => a - b);
          await partnerShiftsConfigMutation.mutateAsync({
            displayedShifts: newConfig,
          });
        }
      } else {
        if (shiftsConfig.displayedShifts.includes(shiftId)) {
          const newConfig = shiftsConfig.displayedShifts.filter(
            (shift) => shift !== shiftId,
          );
          await partnerShiftsConfigMutation.mutateAsync({
            displayedShifts: newConfig,
          });
        }
      }
    } catch {
      snackbar.enqueueSnackbar('Assignment failed to update', {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog
      open
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}>
      <Grid
        item
        display="flex"
        justifyContent="right"
        height={theme.spacing(6)}>
        <Button onClick={hideModal}>
          <Close />
        </Button>
      </Grid>
      <Box px={6} pt={1} pb={2} width={theme.spacing(40)}>
        <Typography variant="h2" textAlign="center">
          Configure Shifts
        </Typography>
        <Box p={2} />
        <Grid container spacing={2} alignItems="center" pl={1} pb={2}>
          <Grid item xs={8}>
            <Typography variant="h4">Shift</Typography>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="center">
            <Typography variant="h4">Included</Typography>
          </Grid>
        </Grid>
        {shifts && shiftsConfig ? (
          <Box
            sx={{ maxHeight: theme.spacing(60), overflowY: 'scroll' }}
            border={2}
            borderColor={theme.palette.divider}
            mr={-3}>
            {shifts?.map((shift) => (
              <Box p={1} key={shift.id}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <Typography>{shift.description}</Typography>
                  </Grid>
                  <Grid item xs={4} display="flex" justifyContent="center">
                    <Checkbox
                      checked={shiftsConfig?.displayedShifts.includes(shift.id)}
                      onChange={(event) => onCheckInclude(event, shift.id)}
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        ) : shiftsLoading || configLoading ? (
          <Loading />
        ) : (
          'An error occured while loading the schedule.'
        )}
        <Box p={1} />
        <Grid display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            onClick={hideModal}
            sx={{ width: theme.spacing(10) }}>
            Close
          </Button>
        </Grid>
      </Box>
    </Dialog>
  );
};
