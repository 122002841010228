import { Button, Grid, Modal, useTheme } from '@mui/material';
import { eachDayOfInterval } from 'date-fns';

import { DateHeaderCell, RowHeaderCell } from 'components/atoms/ScheduleCells';
import { dateToString } from 'util/date';

import { WeeklySchedule } from './WeeklySchedule';
import { useModal } from 'react-modal-hook';
import { AddShiftDialog } from 'components/organisms/AddShiftDialog';
import { useEditState } from 'providers/EditStateProvider';
import { useAuth } from 'providers/AuthProvider';
import { EditWorked } from 'pages/schedule/EditWorked';
import { PlaylistAdd } from '@mui/icons-material';
import { useState } from 'react';
import { buttonSize } from 'util/styling';

export interface WeeklyBlockProps {
  week: Date[];
}

export const WeeklyBlock: React.FC<WeeklyBlockProps> = ({ week }) => {
  const theme = useTheme();
  const { isAdmin } = useAuth();
  const { editState } = useEditState();
  const { isInEditState } = editState;

  const onEditAsWorked = isAdmin
    ? (date: Date) => {
        setEditDate(date);
        if (date) {
          showEditWorkedModal();
        }
      }
    : undefined;

  const buttonStyle = buttonSize(theme);

  const [editDate, setEditDate] = useState<Date | null>(null);

  const [showEditWorkedModal, hideEditWorkedModal] = useModal(
    () => (
      <Modal open={true} onClose={hideEditWorkedModal}>
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%', width: '100%' }}>
          <EditWorked
            date={editDate ?? new Date()}
            hideModal={hideEditWorkedModal}
          />
        </Grid>
      </Modal>
    ),
    [editDate],
  );

  const [showAddShiftModal, hideAddShiftModal] = useModal(() => {
    if (!editDate) {
      return null;
    }
    return <AddShiftDialog monday={editDate} onClose={hideAddShiftModal} />;
  }, [editDate]);

  const onAddShift = (date: Date) => {
    setEditDate(date);
    showAddShiftModal();
  };

  const today = new Date();
  const [weekFrom, weekTo] = week;
  const datesDisplayed = eachDayOfInterval({ start: weekFrom, end: weekTo });
  const daysBeforeDivider = datesDisplayed.slice(0, 3);
  const daysAfterDivider = datesDisplayed.slice(3);
  return (
    <Grid
      key={`${dateToString(weekFrom)}-${dateToString(weekTo)}`}
      pr={4}
      pb={4}>
      <Grid container columns={11} textAlign="center" justifyContent="center">
        <RowHeaderCell alignSelf="end">
          {isInEditState && weekFrom > today && (
            <Button
              variant="contained"
              sx={{ ...buttonStyle }}
              onClick={() => onAddShift(weekFrom)}>
              <PlaylistAdd />
            </Button>
          )}
        </RowHeaderCell>
        {daysBeforeDivider.map((date) => (
          <DateHeaderCell
            key={dateToString(date)}
            onEditAsWorked={onEditAsWorked}
            date={date}
          />
        ))}
        <RowHeaderCell />
        {daysAfterDivider.map((date) => (
          <DateHeaderCell
            key={dateToString(date)}
            onEditAsWorked={onEditAsWorked}
            date={date}
          />
        ))}
      </Grid>
      <WeeklySchedule fromDate={weekFrom} toDate={weekTo} />
    </Grid>
  );
};
