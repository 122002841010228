import { useQuery } from 'react-query';
import { ScheduleConfigService } from 'openapi';

export const useVacationRequestsQuery = (isEnabled: boolean) =>
  useQuery(
    ['requests'],
    async () =>
      await ScheduleConfigService.scheduleConfigServiceGetVacationRequests(),
    { enabled: isEnabled },
  );
