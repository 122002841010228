import { MouseEvent, MouseEventHandler, ReactNode, useState } from 'react';
import {
  Button,
  Grid,
  GridProps,
  IconButton,
  lighten,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { format } from 'date-fns';

import { dateToString } from 'util/date';
import { useHolidaysQuery } from 'hooks/useHolidaysQuery';

export const TableCell: React.FC<GridProps> = (props) => {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingY={theme.spacing(0.5)}
      {...props}
    />
  );
};

export interface RowHeaderCellProps extends GridProps {
  onEdit?: () => void;
  onDelete?: () => void;
}

export const RowHeaderCell: React.FC<RowHeaderCellProps> = ({
  onEdit,
  onDelete,
  children,
  ...gridProps
}) => {
  const theme = useTheme();
  const [isHidden, setIsHidden] = useState(true);

  const buttonDisplay: SxProps = {
    visibility: isHidden ? 'hidden' : 'visible',
    maxWidth: theme.spacing(3),
    minWidth: theme.spacing(3),
    maxHeight: theme.spacing(3),
    minHeight: theme.spacing(3),
    position: 'absolute',
    float: 'right',
  };

  return (
    <TableCell
      {...gridProps}
      style={{ position: 'relative' }}
      xs={2}
      onMouseOver={() => setIsHidden(false)}
      onMouseOut={() => setIsHidden(true)}>
      <Typography variant="h4">{children}</Typography>
      {onEdit && (
        <IconButton
          sx={{
            ...buttonDisplay,
            right: theme.spacing(4),
          }}
          onClick={onEdit}>
          <Edit color="primary" />
        </IconButton>
      )}
      {onDelete && (
        <IconButton
          sx={{
            ...buttonDisplay,
            right: theme.spacing(1),
          }}
          onClick={onDelete}>
          <Delete color="primary" />
        </IconButton>
      )}
    </TableCell>
  );
};

export const DataCell: React.FC<GridProps> = (props) => {
  const { sx, children, ...otherProps } = props;
  const theme = useTheme();
  return (
    <TableCell
      {...otherProps}
      sx={{
        border: '1.5px solid',
        borderColor: theme.palette.grey[600],
        ...sx,
      }}>
      {children}
    </TableCell>
  );
};

export const DateHeaderCell: React.FC<{
  date: Date;
  onEditAsWorked?: (date: Date) => void;
  sx?: SxProps<Theme>;
}> = ({ date, onEditAsWorked, sx }) => {
  const [showHover, setShowHover] = useState(false);
  const showButton = onEditAsWorked && showHover;
  const buttonDisplay: SxProps = {
    visibility: showButton ? 'visible' : 'hidden',
  };

  return (
    <Grid
      item
      xs={1}
      sx={{ ...sx }}
      onMouseOver={() => setShowHover(true)}
      onMouseOut={() => setShowHover(false)}>
      <Typography variant="h3">{format(date, 'eeeee')}</Typography>
      <Typography variant="body1" mt={1}>
        {format(date, 'MMM d')}
      </Typography>
      <Button
        sx={buttonDisplay}
        onClick={() => (onEditAsWorked ? onEditAsWorked(date) : null)}>
        <Edit />
      </Button>
    </Grid>
  );
};

export interface ScheduleCellProps {
  date: string;
  editedAsWorked?: boolean;
  cut?: boolean;
  unpublished?: boolean;
  toBeDeleted?: boolean;
  children?: ReactNode;
  onEdit?: MouseEventHandler;
}

export const ScheduleCell: React.FC<ScheduleCellProps> = ({
  date,
  editedAsWorked,
  cut,
  unpublished,
  toBeDeleted,
  children,
  onEdit,
}) => {
  const theme = useTheme();

  const holidayYear = parseInt(date.substring(0, 4));
  const { data: holidays } = useHolidaysQuery(holidayYear);

  const holiday = holidays?.find((x) => x.date === date) ?? null;
  const holidayTooltip = holiday ? holiday.name : undefined;

  const today = new Date();
  const todayString = dateToString(today);

  const crossHatch: SxProps = {
    background: `repeating-linear-gradient( \
      45deg, \
      ${theme.palette.yellow.light}, \
      ${theme.palette.yellow.light} 10px, \
      ${theme.palette.yellow.main} 10px, \
      ${theme.palette.yellow.main} 20px \
    );`,
  };

  const onClickCell = onEdit
    ? (event: MouseEvent) => {
        onEdit(event);
      }
    : undefined;

  let cellBg: SxProps | undefined = undefined;
  if (editedAsWorked) {
    cellBg = { backgroundColor: lighten(theme.palette.primary.light, 0.6) };
  } else if (cut) {
    cellBg = { backgroundColor: theme.palette.grey[400] };
  } else if (unpublished) {
    cellBg = toBeDeleted
      ? {
          ...crossHatch,
        }
      : {
          backgroundColor: theme.palette.yellow.light,
        };
  } else if (holiday) {
    cellBg = { backgroundColor: theme.palette.yellow.dark };
  } else if (date < todayString) {
    cellBg = { backgroundColor: theme.palette.grey[200] };
  }

  return (
    <DataCell sx={cellBg} title={holidayTooltip} onClick={onClickCell}>
      {children}
    </DataCell>
  );
};
