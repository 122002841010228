import { AuthUser, CreateUserRequest, UsersService } from 'openapi';
import { useMutation, useQueryClient } from 'react-query';

export const useCreateNonPartnerUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user: AuthUser) => {
      const requestBody: CreateUserRequest = {
        userType:
          user.role === CreateUserRequest.userType.HOSPITAL
            ? CreateUserRequest.userType.HOSPITAL
            : CreateUserRequest.userType.NON_PARTNER,
        displayName: user.displayName,
        email: user.email,
        partnerId: undefined,
      };
      const newUser = await UsersService.usersServiceCreateUser(requestBody);
      return UsersService.usersServiceSetUserRole(newUser.uid, {
        role: user.role,
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['users'] });
    },
  });
};
