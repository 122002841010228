import { Link, Outlet } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

import { Cover } from 'components/atoms/Cover';
import { MainCard } from 'components/molecules/MainCard';
import { Logo } from 'components/atoms/Logo';

// Adapted from berrydashboard.io
export const LoginLayout: React.FC = () => {
  return (
    <Cover imageUrl="/anesthesia.jpeg">
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{ minHeight: '100vh' }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: 'calc(100vh - 68px)' }}>
          <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
            <MainCard
              sx={{
                maxWidth: { xs: 400, lg: 475 },
                margin: { xs: 2.5, md: 3 },
                '& > *': {
                  flexGrow: 1,
                  flexBasis: '50%',
                },
              }}
              content={false}>
              <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item sx={{ mb: 3 }}>
                    <Link to="#">
                      <Logo size="large" />
                    </Link>
                  </Grid>
                  <Outlet />
                </Grid>
              </Box>
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
    </Cover>
  );
};
