import { CSSProperties } from 'react';
import { lighten, useTheme } from '@mui/material';

import { useAuth } from 'providers/AuthProvider';
import { useEditState } from 'providers/EditStateProvider';

export const usePartnerHighlightFunction = () => {
  const theme = useTheme();
  const { profile, isAdmin } = useAuth();

  const { highlightedPartner } = useEditState();

  return (partner: number) => {
    const bgShading =
      partner === (highlightedPartner ?? (!isAdmin && profile?.id))
        ? {
            backgroundColor: lighten(theme.palette.secondary.light, 0.6),
          }
        : undefined;
    return bgShading as CSSProperties;
  };
};
