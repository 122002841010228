import React, { useEffect } from 'react';
import { Container } from '@mui/material';

import { AppMenu } from 'components/organisms/AppMenu';
import { Outlet } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';
import { SimpleLogoutBar } from 'components/atoms/SimpleLogoutBar';
import { useEditState } from 'providers/EditStateProvider';

export const MainLayout: React.FC = () => {
  const { isLimited } = useAuth();
  const { setEditState, clearEditState } = useEditState();

  useEffect(() => {
    isLimited ? clearEditState() : setEditState(false);
  }, [isLimited]);

  return (
    <>
      {isLimited ? <SimpleLogoutBar /> : <AppMenu />}
      <main>
        <Container>
          <Outlet />
        </Container>
      </main>
    </>
  );
};
