import { useMutation, useQueryClient } from 'react-query';
import { Partner, PartnersService } from 'openapi';

export const useCreatePartnerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (partner: Partner) => {
      return PartnersService.partnersServiceCreatePartner(partner);
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['partners'] });
    },
  });
};
