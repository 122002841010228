import { Check, Delete, DoDisturb } from '@mui/icons-material';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { VacationRequest } from 'openapi';
import { useAuth } from 'providers/AuthProvider';
import { MainCard } from './MainCard';
import { PriorityHigh } from '@mui/icons-material';
import { usePartnersQuery } from 'hooks/usePartnersQuery';
import { useVacationTypesQuery } from 'hooks/useVacationTypesQuery';
import { buttonSize } from 'util/styling';
import { useVacationRequestApproveMutation } from 'hooks/useVacationRequestApproveMutation';
import { useVacationRequestDenyMutation } from 'hooks/useVacationRequestDenyMutation';
import { useVacationRequestDeleteMutation } from 'hooks/useVacationRequestDeleteMutation';

export interface VacationRequestRowProps {
  request: VacationRequest;
}

export const VacationRequestRow: React.FC<VacationRequestRowProps> = ({
  request,
}) => {
  const theme = useTheme();
  const { isAdmin } = useAuth();
  const snackbar = useSnackbar();
  const { data: partners } = usePartnersQuery();
  const { data: vacationTypes } = useVacationTypesQuery();

  const buttonStyle = buttonSize(theme);

  const approvalStatus = (approved: boolean | null) =>
    approved === null ? 'Pending' : approved ? 'Approved' : 'Denied';

  const approveMutation = useVacationRequestApproveMutation();
  const denyMutation = useVacationRequestDenyMutation();
  const deleteMutation = useVacationRequestDeleteMutation();

  const onApprove = async () => {
    try {
      await approveMutation.mutateAsync(request.id);
      snackbar.enqueueSnackbar('Approved vacation successfully', {
        variant: 'success',
      });
    } catch {
      snackbar.enqueueSnackbar('Failed to approve vacation', {
        variant: 'error',
      });
    }
  };

  const onDeny = async () => {
    try {
      await denyMutation.mutateAsync(request.id);
      snackbar.enqueueSnackbar('Denied vacation successfully', {
        variant: 'success',
      });
    } catch {
      snackbar.enqueueSnackbar('Failed to deny vacation', {
        variant: 'error',
      });
    }
  };

  const onDelete = async () => {
    try {
      await deleteMutation.mutateAsync(request.id);
      snackbar.enqueueSnackbar('Deleted vacation successfully', {
        variant: 'success',
      });
    } catch {
      snackbar.enqueueSnackbar('Failed to delete vacation', {
        variant: 'error',
      });
    }
  };

  const partner = partners?.find((partner) => partner.id === request.partnerId);
  const vacationType = vacationTypes?.find(
    (vacationType) => vacationType.id === request.vacationType,
  );

  const columnCount = isAdmin ? 12 : 10;

  return (
    <Box pb={3}>
      <MainCard>
        <Grid container alignItems="baseline" columns={columnCount}>
          {isAdmin && (
            <Grid item xs={8} md={2}>
              <Typography variant="body1">
                {partner?.initials ?? '???'}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8} md={4}>
            <Typography variant="body1">
              {request.startDate.split('-').join('/')} -{' '}
              {request.endDate.split('-').join('/')}
            </Typography>
          </Grid>
          <Grid item xs={8} md={1}>
            <Typography variant="body1">
              {vacationType?.symbol ?? '???'}
            </Typography>
          </Grid>
          <Grid item xs={8} md={1}>
            <Typography variant="body1">
              {request.priority > 0 ? <PriorityHigh /> : ''}
            </Typography>
          </Grid>
          <Grid item xs={8} md={2}>
            <Typography variant="body1">
              {request.requestDate.split('-').join('/')}
            </Typography>
          </Grid>
          {isAdmin && request.approved === null ? (
            <Grid item xs={8} md={2}>
              <Button onClick={onApprove} sx={{ ...buttonStyle }}>
                <Check />
              </Button>
              <Button onClick={onDeny} sx={{ ...buttonStyle }}>
                <DoDisturb />
              </Button>
              <Box display="inline" sx={{ paddingRight: theme.spacing(4) }} />
              <Button onClick={onDelete} sx={{ ...buttonStyle }}>
                <Delete />
              </Button>
            </Grid>
          ) : (
            <Grid item xs={8} md={2}>
              <Typography display="inline">
                {approvalStatus(request.approved)}
              </Typography>
              <Box display="inline" sx={{ paddingRight: theme.spacing(4) }} />
              <Button onClick={onDelete} sx={{ ...buttonStyle }}>
                <Delete />
              </Button>
            </Grid>
          )}
        </Grid>
      </MainCard>
    </Box>
  );
};
