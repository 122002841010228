import { Box, useTheme } from '@mui/material';

import { Loading } from 'components/atoms/Loading';
import { None, PartnerSelect } from 'components/atoms/PartnerSelect';
import { useScheduleQuery } from 'hooks/useScheduleQuery';
import { Assignment, PartnersService, UnpublishedAssignment } from 'openapi';
import {
  PartnerSelectOption,
  PartnerSelectProvider,
} from 'providers/PartnerSelectProvider';
import { useQuery } from 'react-query';
import { convertDateFromUTC, dateToString, weekSpan } from 'util/date';
import { useSnackbar } from 'notistack';

import { AssignmentCell, ScheduleGrid, VacationCell } from './ScheduleGrid';
import { useAuth } from 'providers/AuthProvider';
import { useUnpublishedAssignmentMutation } from 'hooks/useUnpublishedAssignmentMutation';
import { eachDayOfInterval } from 'date-fns';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { DeleteDialog } from './DeleteDialog';
import { EditShiftDialog } from 'components/organisms/EditShiftDialog';
import { useEditState } from 'providers/EditStateProvider';

export interface WeeklyScheduleProps {
  fromDate: Date;
  toDate: Date;
}

const mapSchedule = <U, V>(
  schedule: Record<string, Record<string, Array<U>>>,
  mapper: (elements: Array<U>) => V,
) => {
  const mapped: Record<string, Record<string, V>> = {};
  Object.keys(schedule).forEach((date) => {
    mapped[date] = {};
    Object.keys(schedule[date]).forEach((slot) => {
      mapped[date][slot] = mapper(schedule[date][slot]);
    });
  });
  return mapped;
};

enum RowEditType {
  None,
  Edit,
  Delete,
}

interface RowEditState {
  editType: RowEditType;
  date?: Date;
  slotId?: number;
}

export const WeeklySchedule: React.FC<WeeklyScheduleProps> = ({
  fromDate,
  toDate,
}) => {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const { isLimited } = useAuth();
  const { editState } = useEditState();
  const { isInEditState } = editState;

  const { isLoading, data: schedule } = useScheduleQuery(
    dateToString(fromDate),
    dateToString(toDate),
    isInEditState,
    isLimited,
  );

  const { data: partners } = useQuery(
    'partners',
    PartnersService.partnersServiceGetPartners,
    {
      enabled: !isLimited,
    },
  );

  const { isAdmin } = useAuth();
  const today = new Date();
  const isEditable = isInEditState && fromDate > today;

  const partnerOptions = [None].concat(
    partners
      ?.filter(
        (partner) =>
          partner.status === 'partner' || partner.status === 'employee'
      )
      .map((partner) => ({
        label: partner.initials ?? '',
        id: partner.id,
        group: '',
      })) ?? [],
  );

  const assignmentMutation = useUnpublishedAssignmentMutation();

  const [rowEditState, setRowEditState] = useState<RowEditState>({
    editType: RowEditType.None,
  });

  const getPartnersAvailable = (date: string) => {
    if (!schedule?.assignments) {
      return partnerOptions;
    }
    // figure out who no longer works on this date
    const terminatedPartnerIds =
      partners?.filter((partner) => partner.endDate && partner.endDate < date)
      .map((partner) => partner.id)
      ?? [];

    // figure out what has already been assigned
    const assignedPartnerIds: Array<number> = [];
    for (const slot in schedule.assignments[date]) {
      if (schedule.assignments[date][slot]) {
        const nontrivial = schedule.assignments[date][slot]
          .map((assignment) => assignment.partnerId)
          .filter((id) => Boolean(id)) as Array<number>;
        assignedPartnerIds.push(...nontrivial);
      }
    }

    // figure out who is on vacation
    const vacationPartnerIds: Array<number> = [];
    for (const slot in schedule.vacations[date]) {
      if (schedule.vacations[date][slot]) {
        const nontrivial = schedule.vacations[date][slot]
          .map((vacation) => vacation.partnerId)
          .filter((id) => Boolean(id)) as Array<number>;
        vacationPartnerIds.push(...nontrivial);
      }
    }

    const unassigned = partnerOptions
      .filter(
        (partner) =>
          !terminatedPartnerIds.includes(partner.id) &&
          !assignedPartnerIds.includes(partner.id) &&
          !vacationPartnerIds.includes(partner.id),
      )
      .sort((a, b) => a.label.localeCompare(b.label));
    const vacation = partnerOptions
      .filter((partner) => vacationPartnerIds.includes(partner.id))
      .map((partner) => ({ ...partner, group: 'Vacation' }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return unassigned.concat(vacation);
  };

  const finishSelect = async (
    value: PartnerSelectOption | null,
    date: string,
    assignment?: Assignment,
  ) => {
    if (assignment && value) {
      if (value === None) {
        assignment.partnerId = undefined;
        assignment.partnerNickname = undefined;
        assignment.workedPartnerId = undefined;
        assignment.workedPartnerNickname = undefined;
        assignment.published = false;
      } else {
        assignment.partnerId = value.id;
        assignment.partnerNickname = value.label;
        assignment.workedPartnerId = value.id;
        assignment.workedPartnerNickname = value.label;
        assignment.published = false;
      }

      try {
        await assignmentMutation.mutateAsync([assignment]);
        // happens so frequently that we'll assume success
        // and limit ourselves to showing errors
      } catch {
        snackbar.enqueueSnackbar('Assignment failed to update', {
          variant: 'error',
        });
      }
    }
  };

  const deleteRow = async (date: Date, slotId: number) => {
    const [weekStart, weekEnd] = weekSpan(date);
    const deletedAssignments: UnpublishedAssignment[] = [];
    eachDayOfInterval({ start: weekStart, end: weekEnd }).forEach((date) => {
      const stringDate = dateToString(date);
      if (
        schedule?.assignments &&
        schedule?.assignments[stringDate] &&
        schedule?.assignments[stringDate][slotId]
      ) {
        schedule?.assignments[stringDate][slotId].forEach((assignment) =>
          deletedAssignments.push({
            ...assignment,
            id: undefined,
            toBeDeleted: true,
          }),
        );
      }
    });
    if (deletedAssignments.length > 0) {
      try {
        await assignmentMutation.mutateAsync(deletedAssignments);
        // limit ourselves to showing errors
      } catch {
        snackbar.enqueueSnackbar('Failed to delete shift', {
          variant: 'error',
        });
      }
    }
  };

  const [showRowEdit, hideRowEdit] = useModal(() => {
    if (
      rowEditState.date === undefined ||
      rowEditState.slotId === undefined ||
      rowEditState.editType !== RowEditType.Edit
    ) {
      setRowEditState({ editType: RowEditType.None });
      hideDeleteConfirm();
      return null;
    }

    const date = rowEditState.date;
    const slotId = rowEditState.slotId;

    return (
      <EditShiftDialog
        monday={date}
        slotId={slotId}
        onClose={async () => {
          setRowEditState({ editType: RowEditType.None });
          hideRowEdit();
        }}
      />
    );
  }, [rowEditState]);

  const [showDeleteConfirm, hideDeleteConfirm] = useModal(() => {
    if (
      rowEditState.date === undefined ||
      rowEditState.slotId === undefined ||
      rowEditState.editType !== RowEditType.Delete
    ) {
      setRowEditState({ editType: RowEditType.None });
      hideDeleteConfirm();
      return null;
    }

    const date = rowEditState.date;
    const slotId = rowEditState.slotId;

    return (
      <DeleteDialog
        onConfirm={async () => {
          await deleteRow(date, slotId);
          setRowEditState({ editType: RowEditType.None });
          hideDeleteConfirm();
        }}
        onCancel={() => {
          setRowEditState({ editType: RowEditType.None });
          hideDeleteConfirm();
        }}
        itemName={'row'}
      />
    );
  }, [rowEditState]);

  return schedule ? (
    <PartnerSelectProvider
      getPartnersAvailable={getPartnersAvailable}
      finishSelect={finishSelect}>
      <ScheduleGrid
        dates={schedule.dates}
        slots={schedule.shifts}
        onEditRow={
          isEditable
            ? (date, slotId) => {
                setRowEditState({
                  editType: RowEditType.Edit,
                  date: convertDateFromUTC(new Date(date)),
                  slotId,
                });
                showRowEdit();
              }
            : undefined
        }
        onDeleteRow={
          isEditable
            ? (date, slotId) => {
                setRowEditState({
                  editType: RowEditType.Delete,
                  date: convertDateFromUTC(new Date(date)),
                  slotId,
                });
                showDeleteConfirm();
              }
            : undefined
        }
        cells={mapSchedule(schedule.assignments, (assignments) => {
          const unpublished = assignments.filter(
            (assignment) => assignment.published === false,
          );
          const published = assignments.filter(
            (assignment) => assignment.published,
          );
          const assignment =
            unpublished.length > 0 ? unpublished[0] : published[0];
          return (
            <AssignmentCell
              key={assignment.date}
              editable={
                isAdmin && isInEditState && new Date(assignment.date) > today
              }
              assignment={assignment}
            />
          );
        })}
      />
      {Object.keys(schedule.vacations).length > 0 && (
        <Box
          height="4px"
          sx={{
            position: 'relative',
            top: '-1px',
            marginBottom: '-2px',
            background: theme.palette.grey[600],
          }}
        />
      )}
      <ScheduleGrid
        dates={schedule.dates}
        slots={schedule.vacationTypes}
        cells={mapSchedule(schedule.vacations, (vacations) => (
          <VacationCell key={vacations[0].date} vacations={vacations} />
        ))}
      />
      <PartnerSelect value={null} />
    </PartnerSelectProvider>
  ) : isLoading ? (
    <Loading />
  ) : (
    <div style={{ textAlign: 'center' }}>
      No schedule is available for these dates.
    </div>
  );
};
