import { ShiftPointsSubform, ShiftPointsSchema } from './ShiftPointsSubform';
import { Form, Formik } from 'formik';
import { Assignment, Shift, UnpublishedAssignment } from 'openapi';
import { useSnackbar } from 'notistack';
import { Button, Grid, Stack, useTheme } from '@mui/material';
import { dateToString } from 'util/date';
import { addDays } from 'date-fns';
import { useUnpublishedAssignmentMutation } from 'hooks/useUnpublishedAssignmentMutation';
import { useScheduleQuery } from 'hooks/useScheduleQuery';
import {
  createAssignmentsFromShift,
  deleteAssignments,
  updateAssignmentPoints,
} from 'util/shiftAssignments';

export interface EditShiftFormProps {
  monday: Date;
  shift: Shift;
  onClose: () => void;
}

export const EditShiftForm: React.FC<EditShiftFormProps> = ({
  monday,
  shift,
  onClose,
}: EditShiftFormProps) => {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const beginDate = dateToString(monday);
  const endDate = dateToString(addDays(monday, 6));

  const shiftMutation = useUnpublishedAssignmentMutation();

  const { data: schedule } = useScheduleQuery(beginDate, endDate, true);

  const assignments: Array<Assignment> = [];
  schedule?.dates.forEach((date) => {
    Object.values(schedule.assignments[date]).forEach((shift) =>
      assignments.push(...shift),
    );
  });
  const shiftAssignments = assignments.filter(
    (assignment) => assignment.shiftId === shift.id,
  );

  return (
    <Formik
      initialValues={{ shift }}
      enableReinitialize
      onSubmit={async (values) => {
        const updatedShift = values.shift;
        const unpublished: Array<UnpublishedAssignment> = [];

        // update points where they need to be updated
        const updated = updateAssignmentPoints(updatedShift, shiftAssignments);

        // add new shifts that should exist
        const created = createAssignmentsFromShift(
          monday,
          addDays(monday, 6),
          updatedShift,
          shiftAssignments,
        );

        // delete shifts that should no longer exist
        const deleted = deleteAssignments(updatedShift, shiftAssignments);

        unpublished.push(...updated, ...created, ...deleted);
        try {
          await shiftMutation.mutateAsync(unpublished);
        } catch {
          snackbar.enqueueSnackbar('Shift failed to update', {
            variant: 'error',
          });
        }
        onClose();
      }}
      validationSchema={ShiftPointsSchema}>
      {(formik) => (
        <Form noValidate>
          <Grid container columnSpacing={1} rowSpacing={2} alignItems="center">
            <ShiftPointsSubform />
          </Grid>
          <Stack pt={4} direction="row" spacing={1} justifyContent="right">
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ width: theme.spacing(10) }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => formik.submitForm()}
              sx={{ width: theme.spacing(10) }}>
              Confirm
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
