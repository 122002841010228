/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Holiday } from '../models/Holiday';
import type { HolidayShift } from '../models/HolidayShift';
import type { HolidayShiftUpdate } from '../models/HolidayShiftUpdate';
import type { NewId } from '../models/NewId';
import type { Shift } from '../models/Shift';
import type { VacationRequest } from '../models/VacationRequest';
import type { VacationType } from '../models/VacationType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScheduleConfigService {

  /**
   * @returns Holiday Retrieves configured holidays for all years
   * @throws ApiError
   */
  public static scheduleConfigServiceGetAllHolidays(): CancelablePromise<Array<Holiday>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schedule/config/holidays/all',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * @param year
   * @returns Holiday Retrieves configured holidays for given year
   * @throws ApiError
   */
  public static scheduleConfigServiceGetHolidays(
    year: number,
  ): CancelablePromise<Array<Holiday>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schedule/config/holidays/{year}',
      path: {
        'year': year,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Holiday Create the holiday for the given date
   * @throws ApiError
   */
  public static scheduleConfigServiceCreateHoliday(
    requestBody: Holiday,
  ): CancelablePromise<Holiday> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/schedule/config/holidays',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns Holiday Update the holiday for the given year
   * @throws ApiError
   */
  public static scheduleConfigServiceUpdateHoliday(
    id: number,
    requestBody: Holiday,
  ): CancelablePromise<Holiday> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/schedule/config/holidays/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @param id
   * @returns string Deletes the holiday (must be unscheduled)
   * @throws ApiError
   */
  public static scheduleConfigServiceDeleteHoliday(
    id: number,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/schedule/config/holidays/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
      },
    });
  }

  /**
   * @param id
   * @returns HolidayShift Retrieves shifts for a holiday
   * @throws ApiError
   */
  public static scheduleConfigServiceGetHolidayShifts(
    id: number,
  ): CancelablePromise<Array<HolidayShift>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schedule/config/holidays/{id}/shifts',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns HolidayShift Updates shifts for a holiday
   * @throws ApiError
   */
  public static scheduleConfigServiceUpdateHolidayShifts(
    id: number,
    requestBody: Array<HolidayShiftUpdate>,
  ): CancelablePromise<Array<HolidayShift>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/schedule/config/holidays/{id}/shifts',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
      },
    });
  }

  /**
   * @returns Shift Retrieves configured shifts
   * @throws ApiError
   */
  public static scheduleConfigServiceGetShifts(): CancelablePromise<Array<Shift>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schedule/config/shifts',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Shift Create the configured shift
   * @throws ApiError
   */
  public static scheduleConfigServiceCreateShift(
    requestBody: Shift,
  ): CancelablePromise<Shift> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/schedule/config/shifts',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @param shiftId
   * @param requestBody
   * @returns Shift Update a configured shift
   * @throws ApiError
   */
  public static scheduleConfigServiceUpdateShift(
    shiftId: number,
    requestBody: Shift,
  ): CancelablePromise<Shift> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/schedule/config/shifts/{shiftId}',
      path: {
        'shiftId': shiftId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
      },
    });
  }

  /**
   * @param shiftId
   * @returns string Deletes the shift (must be unscheduled)
   * @throws ApiError
   */
  public static scheduleConfigServiceDeleteShift(
    shiftId: number,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/schedule/config/shifts/{shiftId}',
      path: {
        'shiftId': shiftId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
      },
    });
  }

  /**
   * @param subjectId
   * @param targetId
   * @returns string Success
   * @throws ApiError
   */
  public static scheduleConfigServiceRerankShifts(
    subjectId: number,
    targetId: number,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/schedule/config/shifts/rerank',
      query: {
        'subjectId': subjectId,
        'targetId': targetId,
      },
      errors: {
        400: `Error`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns VacationType Retrieve a list of vacation types
   * @throws ApiError
   */
  public static scheduleConfigServiceGetVacationTypes(): CancelablePromise<Array<VacationType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schedule/config/vacation-types',
    });
  }

  /**
   * @param requestBody
   * @returns VacationRequest Create a vacation request
   * @returns NewId Ok
   * @throws ApiError
   */
  public static scheduleConfigServiceCreateVacationRequest(
    requestBody: VacationRequest,
  ): CancelablePromise<VacationRequest | NewId> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/schedule/config/requests',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns VacationRequest Retrieve a list of vacation requests
   * @throws ApiError
   */
  public static scheduleConfigServiceGetVacationRequests(): CancelablePromise<Array<VacationRequest>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schedule/config/requests',
    });
  }

  /**
   * @param partnerId
   * @returns VacationRequest Retrieve a list of vacation requests for a specific partner
   * @throws ApiError
   */
  public static scheduleConfigServiceGetVacationRequestsByPartner(
    partnerId: number,
  ): CancelablePromise<Array<VacationRequest>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schedule/config/requests/partner/{partnerId}',
      path: {
        'partnerId': partnerId,
      },
    });
  }

  /**
   * @param requestId
   * @returns string Ok
   * @throws ApiError
   */
  public static scheduleConfigServiceDeleteVacationRequest(
    requestId: number,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/schedule/config/requests/{requestId}',
      path: {
        'requestId': requestId,
      },
      errors: {
        401: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @param requestId
   * @returns string Success
   * @throws ApiError
   */
  public static scheduleConfigServiceApproveVacationRequest(
    requestId: number,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/schedule/config/requests/{requestId}/approve',
      path: {
        'requestId': requestId,
      },
      errors: {
        400: `Error`,
        401: `Unauthorized`,
      },
    });
  }

  /**
   * @param requestId
   * @returns string Success
   * @throws ApiError
   */
  public static scheduleConfigServiceDenyVacationRequest(
    requestId: number,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/schedule/config/requests/{requestId}/deny',
      path: {
        'requestId': requestId,
      },
      errors: {
        400: `Error`,
        401: `Unauthorized`,
      },
    });
  }

}
