import { CreateUserRequest, UsersService } from 'openapi';
import { useMutation, useQueryClient } from 'react-query';

export const useCreateUserFromPartnerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (partnerId: number) => {
      const requestBody: CreateUserRequest = {
        userType: CreateUserRequest.userType.PARTNER,
        partnerId: partnerId,
      };
      return UsersService.usersServiceCreateUser(requestBody);
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['users'] });
      queryClient.refetchQueries({ queryKey: ['partners'] });
    },
  });
};
