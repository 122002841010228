import { Grid, Stack, Typography } from '@mui/material';

import { RowHeaderCell, ScheduleCell } from 'components/atoms/ScheduleCells';
import { ShiftWithId } from 'hooks/useScheduleQuery';
import { usePartnerHighlightFunction } from 'hooks/usePartnerHighlightFunction';
import { Assignment, Vacation } from 'openapi';
import { usePartnerSelect } from 'providers/PartnerSelectProvider';
import { CSSProperties, MouseEvent, ReactNode } from 'react';

export interface ScheduleGridProps {
  slots: Array<ShiftWithId>;
  dates: Array<string>;
  cells: Record<string, Record<string, ReactNode>>;
  onEditRow?: (date: string, shift: number) => void;
  onDeleteRow?: (date: string, shift: number) => void;
}

export interface AssignmentCellProps {
  editable?: boolean;
  assignment: Assignment;
}

export const AssignmentCell: React.FC<AssignmentCellProps> = ({
  editable,
  assignment,
}) => {
  const nameHighlight = usePartnerHighlightFunction();
  const partnerSelect = usePartnerSelect();

  const highlight = assignment?.workedPartnerId
    ? nameHighlight(assignment.workedPartnerId)
    : undefined;

  const onEdit = editable
    ? (event: MouseEvent) => {
        partnerSelect.startSelect(
          event.currentTarget,
          assignment.date,
          assignment,
        );
      }
    : undefined;

  return (
    <ScheduleCell
      date={assignment.date}
      unpublished={assignment.published === false}
      editedAsWorked={assignment.workedPartnerId !== assignment.partnerId}
      onEdit={onEdit}
      cut={assignment.cut}
      toBeDeleted={assignment.toBeDeleted}>
      <Typography variant="body1" textAlign="center" px={1.5}>
        <span style={highlight}>{assignment?.workedPartnerNickname}</span>
      </Typography>
    </ScheduleCell>
  );
};

export interface VacationCellProps {
  vacations: Array<Vacation>;
}

export const VacationCell: React.FC<VacationCellProps> = ({ vacations }) => {
  const nameHighlight = usePartnerHighlightFunction();

  if (vacations.length === 0) return null;

  const date = vacations[0].date;

  return (
    <ScheduleCell date={date}>
      <Stack spacing={1}>
        {vacations.map((vacation) => {
          const highlight = vacation.partnerId
            ? nameHighlight(vacation.partnerId)
            : undefined;
          const spanStyle: CSSProperties = {
            ...highlight,
          };

          return (
            <Typography
              key={`${vacation.partnerNickname}`}
              variant="body1"
              textAlign="center"
              px={1.5}>
              <span style={spanStyle}>{vacation.partnerNickname}</span>
            </Typography>
          );
        })}
      </Stack>
    </ScheduleCell>
  );
};

export const ScheduleGrid: React.FC<ScheduleGridProps> = ({
  slots,
  dates,
  cells,
  onEditRow,
  onDeleteRow,
}) => {
  const datesBeforeDivider = dates.slice(0, 3);
  const datesAfterDivider = dates.slice(3);

  return (
    <>
      {slots.map((slot) => (
        <Grid key={slot.id} container columns={11}>
          <RowHeaderCell
            onEdit={onEditRow ? () => onEditRow(dates[0], slot.id) : undefined}
            onDelete={
              onDeleteRow ? () => onDeleteRow(dates[0], slot.id) : undefined
            }>
            {slot.name}
          </RowHeaderCell>
          {datesBeforeDivider.map(
            (date) =>
              cells[date]?.[slot.id] ?? [
                <ScheduleCell key={date} date={date} />,
              ],
          )}
          <RowHeaderCell>{slot.name}</RowHeaderCell>
          {datesAfterDivider.map(
            (date) =>
              cells[date]?.[slot.id] ?? [
                <ScheduleCell key={date} date={date} />,
              ],
          )}
        </Grid>
      ))}
    </>
  );
};
