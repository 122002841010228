import { Route, Routes } from 'react-router';
import { Navigate } from 'react-router-dom';

import { AuthRequired } from 'components/AuthRequired';
import { LoginLayout } from 'components/templates/LoginLayout';
import {
  CompReport,
  Documents,
  Holidays,
  Login,
  ForgotPassword,
  Partners,
  PartnerSchedule,
  Profile,
  Requests,
  Schedule,
  Shifts,
} from 'pages';
import { PartnerShiftsReport } from 'pages/reports/PartnerShiftsReport';
import { useNewRelic } from 'hooks/useNewRelic';

function App() {
  useNewRelic();
  return (
    <Routes>
      <Route path="/login" element={<LoginLayout />}>
        <Route index element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
      </Route>
      <Route path="/" element={<AuthRequired />}>
        <Route path="documents" element={<Documents />} />
        <Route path="partners" element={<Partners />} />
        <Route path="profile" element={<Profile />} />
        <Route
          path="reports"
          element={<Navigate to="/reports/compensation" replace />}
        />
        <Route path="reports/compensation" element={<CompReport />} />
        <Route path="reports/shifts" element={<PartnerShiftsReport />} />
        <Route path="schedule" element={<Schedule />} />
        <Route path="schedule/partner" element={<PartnerSchedule />} />
        <Route path="schedule/holidays" element={<Holidays />} />
        <Route path="schedule/shifts" element={<Shifts />} />
        <Route path="schedule/requests" element={<Requests />} />
        <Route index element={<Navigate to="/schedule" replace />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}

export default App;
