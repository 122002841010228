export const getReadableFileSize = (fileSizeInBytes: number) => {
  if (fileSizeInBytes < 1024) return `${fileSizeInBytes} B`;

  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = -1;
  let reducedFileSize: number = fileSizeInBytes;
  while (reducedFileSize > 1000) {
    reducedFileSize /= 1000;
    i++;
  }

  return Math.max(reducedFileSize, 0.1).toFixed(1) + byteUnits[i];
};
