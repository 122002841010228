import { useMutation, useQueryClient } from 'react-query';

import { Assignment, ScheduleService } from 'openapi';

export const useAssignmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (assignment: Assignment) => {
      return ScheduleService.scheduleServiceUpdateScheduleAsWorked({
        assignments: [assignment],
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['schedule'] });
      queryClient.refetchQueries({ queryKey: ['holidaysWithShifts'] });
      queryClient.invalidateQueries({ queryKey: ['compReport'] });
    },
  });
};
