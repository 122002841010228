import { useMutation, useQueryClient } from 'react-query';

import { ScheduleConfigService } from 'openapi';

export const useDeleteHolidayMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (holidayId: number) => {
      return ScheduleConfigService.scheduleConfigServiceDeleteHoliday(
        holidayId,
      );
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['holidays'] });
      queryClient.refetchQueries({ queryKey: ['holidaysWithShifts'] });
    },
  });
};
