import {
  Assignment,
  ScheduleConfigService,
  ScheduleService,
  Vacation,
} from 'openapi';
import { useQuery } from 'react-query';

interface WithId {
  id: number;
}

export interface VacationWithId extends WithId {
  name: string;
}
export interface ShiftWithId extends WithId {
  name: string;
}

export const useScheduleQuery = (
  fromDate: string,
  toDate: string,
  includeUnpublished: boolean,
  limitedOnly?: boolean,
) =>
  useQuery(
    ['schedule', fromDate, toDate, includeUnpublished, limitedOnly],
    async () => {
      const shifts =
        await ScheduleConfigService.scheduleConfigServiceGetShifts();
      const schedule = limitedOnly
        ? await ScheduleService.scheduleServiceGetLimitedSchedule(
            fromDate,
            toDate,
          )
        : await ScheduleService.scheduleServiceGetSchedule(
            fromDate,
            toDate,
            includeUnpublished,
          );
      const allDates = schedule.dates.map((workday) => workday.date);
      const allShifts = new Array<ShiftWithId>();
      const allVacationTypes = new Array<VacationWithId>();
      schedule.dates.forEach((workday) => {
        workday.assignments.forEach((assignment) => {
          if (!allShifts.find((shift) => shift.id === assignment.shiftId)) {
            allShifts.push({
              id: assignment.shiftId,
              name: assignment.shiftSymbol ?? '',
            });
          }
        });
        workday.vacations.forEach((vacation) => {
          if (
            !allVacationTypes.find(
              (vacationType) => vacationType.id === vacation.vacationTypeId,
            )
          ) {
            allVacationTypes.push({
              id: vacation.vacationTypeId,
              name: vacation.vacationType ?? '',
            });
          }
        });
      });
      const assignments: Record<string, Record<number, Array<Assignment>>> = {};
      const vacations: Record<string, Record<number, Array<Vacation>>> = {};
      schedule.dates.forEach((workday) => {
        workday.assignments.forEach((assignment) => {
          if (!assignments[workday.date]) {
            assignments[workday.date] = {};
          }
          if (assignment?.shiftSymbol) {
            if (!assignments[workday.date][assignment.shiftId]) {
              assignments[workday.date][assignment.shiftId] = [];
            }
            if (
              !assignments[workday.date][assignment.shiftId].find(
                (a) => a.id === assignment.id,
              )
            ) {
              assignments[workday.date][assignment.shiftId].push(assignment);
            }
          }
          assignments[workday.date][assignment.shiftId].sort((a, b) => {
            if (a.workedPartnerNickname && b.workedPartnerNickname) {
              return a.workedPartnerNickname?.localeCompare(
                b.workedPartnerNickname,
              );
            }
            return 0;
          });
        });
        workday.vacations.forEach((vacation) => {
          if (!vacations[workday.date]) {
            vacations[workday.date] = {};
          }
          if (vacation?.vacationType) {
            if (!vacations[workday.date][vacation?.vacationTypeId]) {
              vacations[workday.date][vacation?.vacationTypeId] = [];
            }
            if (
              !vacations[workday.date][vacation.vacationTypeId].find(
                (v) => v.partnerId === vacation.partnerId,
              )
            ) {
              vacations[workday.date][vacation?.vacationTypeId].push(vacation);
            }
          }
          vacations[workday.date][vacation?.vacationTypeId].sort((a, b) => {
            if (a.partnerNickname && b.partnerNickname) {
              return a.partnerNickname?.localeCompare(b.partnerNickname);
            }
            return 0;
          });
        });
      });

      const shiftsOrdered = Array.from(allShifts).sort((a, b) => {
        const shiftA = shifts.find((shift) => shift.id === a.id);
        const shiftB = shifts.find((shift) => shift.id === b.id);
        if (!shiftA || !shiftB) {
          return 0; // this should realistically never happen
        }
        return shiftA.rank - shiftB.rank;
      });

      return {
        dates: allDates,
        shifts: shiftsOrdered,
        assignments,
        vacationTypes: Array.from(allVacationTypes),
        vacations,
      };
    },
  );
