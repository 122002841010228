// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import { ForgotPasswordForm } from 'components/forms/ForgotPasswordForm';

// Adapted from berrydashboard.io
export const ForgotPassword: React.FC = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{ width: '100%' }}>
      <Typography
        color={theme.palette.secondary.main}
        gutterBottom
        variant={matchDownSM ? 'h3' : 'h2'}>
        Forgot password?
      </Typography>
      <Typography variant="caption" fontSize="16px" textAlign="center">
        Enter your email address below and we&apos;ll send you password reset
        OTP.
      </Typography>
      <Box sx={{ width: '100%' }}>
        <ForgotPasswordForm />
      </Box>
    </Stack>
  );
};
