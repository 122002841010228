import { MouseEvent } from 'react';
import { Button, Grid, SxProps, useTheme } from '@mui/material';
import { useMenu } from 'providers/MenuProvider';
import { MenuItemDescriptor } from 'types/appbar';
import { AppbarMenu } from './AppbarMenu';

export interface AppbarButtonProps {
  align?: string;
  style?: string;
  action?: () => void;
  menuItems?: Array<MenuItemDescriptor>;
  children: React.ReactNode;
}

export const AppbarButton: React.FC<AppbarButtonProps> = ({
  align,
  style,
  action,
  menuItems,
  children,
}) => {
  const theme = useTheme();
  const { setMenu } = useMenu();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (action) {
      action();
    } else if (menuItems) {
      setMenu(event.currentTarget, <AppbarMenu menuItems={menuItems} />);
    }
  };

  const alignment: SxProps =
    align === 'left'
      ? { justifyContent: 'left', mr: 2, flexGrow: 1 }
      : { justifyContent: 'center', width: theme.spacing(15) };

  const buttonStyle: SxProps = style === 'icon' ? {} : { width: '100%' };

  return (
    <Grid item sx={{ ...alignment }}>
      <Button
        disableRipple
        color="inherit"
        sx={{ ...buttonStyle }}
        onClick={handleClick}>
        {children}
      </Button>
    </Grid>
  );
};
