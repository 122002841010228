import { useQuery } from 'react-query';

import { DocumentsService } from 'openapi';
import { useMemo } from 'react';

export const useDocumentLinksQuery = () =>
  useQuery('documentLinks', DocumentsService.documentsServiceGetDocumentLinks);

export const useDocumentLinkFolders = () => {
  const { data: externalLinks } = useDocumentLinksQuery();

  const linkFolders = useMemo(() => {
    if (!externalLinks) {
      return new Array<string>();
    }
    const uniqueDirectories = new Set<string>();
    externalLinks.forEach((item) => uniqueDirectories.add(item?.folder ?? '/'));
    return Array.from(uniqueDirectories);
  }, [externalLinks]);

  return linkFolders;
};
