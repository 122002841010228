import { useMutation, useQueryClient } from 'react-query';

import { ScheduleConfigService, Shift } from 'openapi';

interface ShiftRerankMutationParams {
  startIndex: number;
  endIndex: number;
  subjectShiftId: number;
  targetShiftId: number;
}

export const useShiftRerankMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (mutationVars: ShiftRerankMutationParams) => {
      const { subjectShiftId, targetShiftId } = mutationVars;
      return ScheduleConfigService.scheduleConfigServiceRerankShifts(
        subjectShiftId,
        targetShiftId,
      );
    },
    onMutate: (mutationVars: ShiftRerankMutationParams) => {
      const { startIndex, endIndex } = mutationVars;
      queryClient.setQueryData(['shifts'], (shifts: Shift[] | undefined) => {
        if (shifts === undefined) {
          return [];
        }

        // this reorders them approximately but the result itself will have to be requeried
        const result = [...shifts];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
      });
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['shifts'] });
    },
  });
};
