import { AttachFile, Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  FolderAutocomplete,
  FolderOption,
  SelectedFolder,
} from 'components/molecules/FolderAutocomplete';
import { useUploadDocumentMutation } from 'hooks/useUploadDocumentMutation';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

export interface DocumentUploadFormProps {
  directories: Array<string>;
  onClose: () => void;
}

export const DocumentUploadForm: React.FC<DocumentUploadFormProps> = ({
  directories,
  onClose,
}) => {
  const snackbar = useSnackbar();
  const theme = useTheme();
  const [isUploading, setIsUploading] = useState(false);
  const uploadDocumentMutation = useUploadDocumentMutation();

  const folderOptions: Array<FolderOption> = [
    {
      name: '/',
      display: 'General',
    },
  ].concat(
    directories
      .filter((directory) => directory !== '/')
      .map((directory) => ({
        name: directory,
        display: directory,
      })),
  );

  const [folder, setFolder] = useState<SelectedFolder>(folderOptions[0]);
  const [file, setFile] = useState<File | undefined>();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFile(event.target.files?.[0]);
  };

  const handleUpload = async () => {
    if (file && folder) {
      setIsUploading(true);
      try {
        await uploadDocumentMutation.mutateAsync({
          dirName: typeof folder === 'string' ? folder : folder.name,
          doc: file,
        });
        snackbar.enqueueSnackbar('Uploaded file succesfully', {
          variant: 'success',
        });
        onClose();
      } catch {
        snackbar.enqueueSnackbar('Failed to upload file', {
          variant: 'error',
        });
      }
      setIsUploading(false);
    } else {
      snackbar.enqueueSnackbar('Failed to upload file', {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="md"
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        alignSelf: 'center',
      }}>
      <Grid container justifyContent="right">
        <Button onClick={onClose}>
          <Close />
        </Button>
      </Grid>
      <Typography variant="h2" textAlign="center">
        Upload a File
      </Typography>
      <Box py={4}>
        <Grid
          container
          justifyItems="center"
          alignItems="center"
          columnSpacing={2}
          rowSpacing={2}
          px={4}>
          <Grid item xs={4} sm={2} textAlign="center">
            <FormLabel>To folder</FormLabel>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FolderAutocomplete
              folderOptions={folderOptions}
              selected={folder}
              setSelected={setFolder}
            />
          </Grid>
          <Grid item xs={2} sm={4}></Grid>
          <Grid item xs={4} sm={2} textAlign="center">
            <FormLabel>File</FormLabel>
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField fullWidth value={file?.name ?? ''} />
          </Grid>
          <Grid item xs={2} sm={4}>
            <Button variant="contained" component="label">
              <AttachFile />
              Add
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Stack direction="row" pt={4} spacing={2}>
              <Button
                variant="outlined"
                onClick={onClose}
                sx={{ width: theme.spacing(16) }}>
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={handleUpload}
                disabled={isUploading}
                sx={{ width: theme.spacing(16) }}>
                {isUploading && (
                  <Box display="flex" justifyContent="center" pr={1}>
                    <CircularProgress size={theme.spacing(3)} color="primary" />
                  </Box>
                )}
                Upload
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
