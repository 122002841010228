import { useEffect, useState } from 'react';
import {
  Droppable as OriginalDroppable,
  DroppableProps,
} from 'react-beautiful-dnd';

// credit to this blog post for resolving a problem with Droppable and strict mode with React 18
// https://medium.com/@wbern/getting-react-18s-strict-mode-to-work-with-react-beautiful-dnd-47bc909348e4

export const Droppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <OriginalDroppable {...props}>{children}</OriginalDroppable>;
};
