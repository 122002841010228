import React, { ChangeEvent, useState } from 'react';
import {
  Button,
  FormLabel,
  Grid,
  MenuItem,
  Stack,
  useTheme,
} from '@mui/material';
import { Field, Form, Formik, FormikContextType } from 'formik';
import { Select, TextField } from 'formik-mui';
import { MobileDatePicker as DatePicker } from 'formik-mui-x-date-pickers';
import * as Yup from 'yup';

import { Partner } from 'openapi/models/Partner';
import { AuthUser } from 'openapi';
import { InlineFormActions } from 'components/molecules/InlineFormActions';
import { MultiFieldError } from 'components/atoms/MultiFieldError';
import { useAuth } from 'providers/AuthProvider';
import { useMediaSize } from 'hooks/useMediaSize';
import 'util/yupExtensions';
import { convertDateFromUTC, dateToString } from 'util/date';

export interface ProfileFormProps {
  mode?: 'inline' | 'modal';
  partner: Partner;
  partnerUser: AuthUser;
  onSubmit: (partner: Partner, user: AuthUser) => void;
  onCancel?: () => void;
}

const ProfileSchema = Yup.object().shape({
  partner: Yup.object({
    firstName: Yup.string()
      .min(2, 'Please enter a first name')
      .max(50, 'Please enter a first name no longer than 50 characters')
      .required('First name is required'),
    middleName: Yup.string()
      .max(
        50,
        'Please enter a middle name no longer than 50 characters maximum',
      )
      .nullable(),
    lastName: Yup.string()
      .min(2, 'Please enter a last name')
      .max(50, 'Please enter a last name no longer than 50 characters')
      .required('Last name is required'),
    initials: Yup.string()
      .max(5, 'Please enter initials 5 characters or less')
      .uppercase('Please use upper case character')
      .required('Initials are required'),
    status: Yup.string().required('Status is required'),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    street: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string()
      .matches(
        /^[A-Z]{2}$/,
        'Please enter the two-character state abbreviation',
      )
      .nullable(),
    zip: Yup.number()
      .min(10000, 'Please enter a valid zip code')
      .max(99999, 'Please enter a valid zip code')
      .nullable(),
    mobile: Yup.string().phoneNumberIsh().required('Mobile phone is required'),
    phone: Yup.string().nullable().phoneNumberIsh(),
  }),
  partnerUser: Yup.object({
    role: Yup.string().required('Access Level is required'),
  }),
});

const toDateMaybe = (dateString?: string) =>
  dateString ? convertDateFromUTC(new Date(dateString)) : null;

const fromStringMaybe = (dateNullable: Date | null) =>
  dateNullable ? dateToString(dateNullable) : undefined;

export const ProfileForm: React.FC<ProfileFormProps> = ({
  mode,
  partner,
  partnerUser,
  onSubmit,
  onCancel,
}: ProfileFormProps) => {
  const theme = useTheme();
  const mediaSize = useMediaSize();
  const labelAlign = mediaSize === 'XS' ? 'left' : 'center';
  const isInline = mode ? mode !== 'modal' : true;
  const [isViewOnly, setViewOnly] = useState(isInline);

  const disableHelper = { sx: { display: 'none' } };
  const helperTextRow = { sx: { mt: theme.spacing(-2) } };

  const makeUpperCase = (
    formik: FormikContextType<ProfileFormProps>,
    inputName: keyof Partner,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const upperCase = event.target.value.toLocaleUpperCase();
    formik.handleChange(event);
    formik.setValues({
      ...formik.values,
      [inputName]: upperCase,
    });
  };

  const { authUser, profile } = useAuth();
  const canEdit = authUser?.role === 'admin' || profile?.id === partner.id;

  const partnerWithDates = {
    ...partner,
    birthday: toDateMaybe(partner.birthday),
    hireDate: toDateMaybe(partner.hireDate),
    endDate: toDateMaybe(partner.endDate),
    partnerDate: toDateMaybe(partner.partnerDate),
    retirementDate: toDateMaybe(partner.retirementDate),
  };

  return (
    <Formik
      initialValues={{
        partner: partnerWithDates,
        partnerUser,
        onSubmit,
      }}
      onSubmit={async (values) => {
        const partner = {
          ...values.partner,
          birthday: fromStringMaybe(values.partner.birthday),
          hireDate: fromStringMaybe(values.partner.hireDate),
          endDate: fromStringMaybe(values.partner.endDate),
          partnerDate: fromStringMaybe(values.partner.partnerDate),
          retirementDate: fromStringMaybe(values.partner.retirementDate),
        };
        onSubmit(partner, values.partnerUser);
        setViewOnly(true);
      }}
      validationSchema={ProfileSchema}>
      {(formik) => (
        <Form noValidate>
          {isInline && canEdit && (
            <InlineFormActions
              isViewOnly={isViewOnly}
              setViewOnly={setViewOnly}
            />
          )}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2} textAlign={labelAlign}>
              <FormLabel>Name</FormLabel>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Field
                name="partner.firstName"
                component={TextField}
                label="First Name"
                disabled={isViewOnly}
                FormHelperTextProps={disableHelper}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={1}>
              <Field
                name="partner.middleName"
                component={TextField}
                label="Middle Name"
                disabled={isViewOnly}
                FormHelperTextProps={disableHelper}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={4}>
              <Field
                name="partner.lastName"
                component={TextField}
                label="Last Name"
                disabled={isViewOnly}
                FormHelperTextProps={disableHelper}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <Field
                name="partner.initials"
                component={TextField}
                label="Initials"
                disabled={isViewOnly}
                FormHelperTextProps={disableHelper}
              />
            </Grid>
            <Grid item xs={12} sm={2} {...helperTextRow} />
            <Grid item xs={12} sm={10} {...helperTextRow}>
              <MultiFieldError
                fields={[
                  'partner.firstName',
                  'partner.middleName',
                  'partner.lastName',
                  'partner.initials',
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={2} textAlign={labelAlign}>
              <FormLabel>Status</FormLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="partner.status"
                component={Select}
                label="Status"
                disabled={isViewOnly}>
                <MenuItem value={'partner'}>Partner</MenuItem>
                <MenuItem value={'employee'}>Employee</MenuItem>
                <MenuItem value={'midlevel'}>Mid-Level</MenuItem>
                <MenuItem value={'retired'}>Retired</MenuItem>
                <MenuItem value={'former_employee'}>Former Employee</MenuItem>
              </Field>
            </Grid>
            <Grid item xs={12} sm={2} textAlign={labelAlign}>
              <FormLabel>Access Level</FormLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="partnerUser.role"
                component={Select}
                label="Access Level"
                disabled={isViewOnly}>
                <MenuItem value={'partner'}>Employee</MenuItem>
                <MenuItem value={'admin'}>Admin</MenuItem>
                <MenuItem value={'hospital'}>Hospital</MenuItem>
              </Field>
            </Grid>
            <Grid item xs={12} sm={2} {...helperTextRow} />
            <Grid item xs={12} sm={10} {...helperTextRow}>
              <MultiFieldError
                fields={['partner.status', 'partnerUser.role']}
              />
            </Grid>

            <Grid item xs={12} sm={2} textAlign={labelAlign}>
              <FormLabel>Spouse</FormLabel>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Field
                name="partner.spouseName"
                component={TextField}
                label="Spouse"
                disabled={isViewOnly}
              />
            </Grid>

            <Grid item xs={12} sm={2} textAlign={labelAlign}>
              <FormLabel>Email</FormLabel>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Field
                name="partner.email"
                component={TextField}
                label="Email"
                disabled={isViewOnly}
              />
            </Grid>

            <Grid item xs={12} sm={2} textAlign={labelAlign}>
              <FormLabel>Address</FormLabel>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Field
                name="partner.line1"
                component={TextField}
                label="Street Address"
                disabled={isViewOnly}
              />
            </Grid>
            <Grid item xs={12} sm={2} />
            <Grid item xs={12} sm={5} md={6} lg={7}>
              <Field
                name="partner.city"
                component={TextField}
                label="City"
                disabled={isViewOnly}
                FormHelperTextProps={disableHelper}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={1}>
              <Field
                name="partner.state"
                component={TextField}
                label="State"
                disabled={isViewOnly}
                FormHelperTextProps={disableHelper}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  makeUpperCase(formik, 'state', event)
                }
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2}>
              <Field
                name="partner.zip"
                component={TextField}
                label="Zip Code"
                disabled={isViewOnly}
                FormHelperTextProps={disableHelper}
              />
            </Grid>
            <Grid item xs={12} sm={2} {...helperTextRow} />
            <Grid item xs={12} sm={10} {...helperTextRow}>
              <MultiFieldError
                fields={['partner.city', 'partner.state', 'partner.zip']}
              />
            </Grid>

            <Grid item xs={12} sm={2} textAlign={labelAlign}>
              <FormLabel>Phone</FormLabel>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Field
                name="partner.mobile"
                component={TextField}
                label="Mobile Phone"
                disabled={isViewOnly}
              />
            </Grid>
            <Grid item xs={12} sm={2} />
            <Grid item xs={12} sm={10}>
              <Field
                name="partner.phone"
                component={TextField}
                label="Alternate Phone"
                disabled={isViewOnly}
              />
            </Grid>

            <Grid item xs={12} sm={2} textAlign={labelAlign}>
              <FormLabel>Hire Date</FormLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="partner.hireDate"
                component={DatePicker}
                label="Hire Date"
                FormHelperTextProps={disableHelper}
                disabled={isViewOnly}
              />
            </Grid>
            <Grid item xs={12} sm={2} textAlign={labelAlign}>
              <FormLabel>Partner Date</FormLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="partner.partnerDate"
                component={DatePicker}
                label="Partner Date"
                FormHelperTextProps={disableHelper}
                disabled={isViewOnly}
              />
            </Grid>
            <Grid item xs={12} sm={2} {...helperTextRow} />
            <Grid item xs={12} sm={10} {...helperTextRow}>
              <MultiFieldError
                fields={['partner.hireDate', 'partner.partnerDate']}
              />
            </Grid>

            <Grid item xs={12} sm={2} textAlign={labelAlign}>
              <FormLabel>Retirement Date</FormLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="partner.retirementDate"
                component={DatePicker}
                label="Retirement Date"
                FormHelperTextProps={disableHelper}
                disabled={isViewOnly}
              />
            </Grid>
            <Grid item xs={12} sm={2} textAlign={labelAlign}>
              <FormLabel>End Date</FormLabel>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="partner.endDate"
                component={DatePicker}
                label="End Date"
                FormHelperTextProps={disableHelper}
                disabled={isViewOnly}
              />
            </Grid>
            <Grid item xs={12} sm={2} {...helperTextRow} />
            <Grid item xs={12} sm={10} {...helperTextRow}>
              <MultiFieldError
                fields={['partner.retirementDate', 'partner.endDate']}
              />
            </Grid>
            {!isInline && (
              <Stack direction="row" pt={4} spacing={2} marginLeft="auto">
                <Button
                  variant="outlined"
                  onClick={onCancel}
                  sx={{ width: theme.spacing(10) }}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ width: theme.spacing(10) }}>
                  Create
                </Button>
              </Stack>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
