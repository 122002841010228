import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { DocumentLink } from 'openapi';
import { Button, FormLabel, Grid, Stack, useTheme } from '@mui/material';
import { TextField } from 'formik-mui';
import {
  FolderAutocomplete,
  FolderOption,
  SelectedFolder,
} from 'components/molecules/FolderAutocomplete';
import { useState } from 'react';
import { useDocumentLinkFolders } from 'hooks/useDocumentLinkQuery';

export interface DocumentLinkFormProps {
  mode: 'create' | 'update';
  documentLink: DocumentLink;
  onSubmit: (documentLink: DocumentLink) => void;
  onCancel?: () => void;
}

const DocumentLinkSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Please enter a name')
    .max(50, 'Name should not be longer than 50 characters')
    .required('Name is required'),
  url: Yup.string()
    .min(1, 'Please enter a URL')
    .max(500, 'URL should not be longer than 500 characters')
    .required('URL is required'),
});

const getFolderString = (folder: SelectedFolder) => {
  if (!folder) {
    return '/';
  }
  if (typeof folder === 'string') {
    return folder;
  }
  return folder.name;
};

export const DocumentLinkForm: React.FC<DocumentLinkFormProps> = ({
  mode,
  documentLink,
  onSubmit,
  onCancel,
}: DocumentLinkFormProps) => {
  const theme = useTheme();

  const linkFolders = useDocumentLinkFolders();

  const folderOptions: Array<FolderOption> = [
    {
      name: '/',
      display: 'General',
    },
  ].concat(
    linkFolders
      .filter((directory) => directory !== '/')
      .map((directory) => ({
        name: directory,
        display: directory,
      })),
  );

  const [folder, setFolder] = useState<SelectedFolder>(
    documentLink
      ? folderOptions.find((option) => option.name === documentLink.folder) ??
          folderOptions[0]
      : folderOptions[0],
  );

  return (
    <Formik
      initialValues={{
        ...documentLink,
      }}
      onSubmit={async (values) => {
        onSubmit({
          ...values,
          folder: getFolderString(folder),
        });
      }}
      validationSchema={DocumentLinkSchema}>
      <Form noValidate>
        <Grid
          container
          rowSpacing={2}
          alignItems="center"
          sx={{ maxWidth: 720, marginLeft: 'auto', marginRight: 'auto' }}>
          <Grid item xs={12} sm={2} textAlign="center">
            <FormLabel>To folder</FormLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <FolderAutocomplete
              folderOptions={folderOptions}
              selected={folder}
              setSelected={setFolder}
            />
          </Grid>
          <Grid item xs={12} sm={2} textAlign="center">
            <FormLabel>Description</FormLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Field name="name" component={TextField} label="Link Name" />
          </Grid>
          <Grid item xs={12} sm={2} textAlign="center">
            <FormLabel>URL</FormLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Field name="url" component={TextField} label="URL" />
          </Grid>
          <Stack direction="row" pt={4} spacing={2} marginLeft="auto">
            <Button
              variant="outlined"
              onClick={onCancel}
              sx={{ width: theme.spacing(10) }}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ width: theme.spacing(10) }}>
              {mode === 'create' ? 'Create' : 'Update'}
            </Button>
          </Stack>
        </Grid>
      </Form>
    </Formik>
  );
};
