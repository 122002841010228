import {
  Box,
  Button,
  Dialog,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

export interface DeleteDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  itemName: string;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  onConfirm,
  onCancel,
  itemName,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={true}
      sx={{
        width: theme.spacing(50),
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
      <Box p={4}>
        <Typography variant="body1">
          Are you sure you would like to delete this {itemName}?
        </Typography>
        <Box p={2} />
        <Stack direction="row" spacing={2} justifyContent="right">
          <Button
            variant="outlined"
            onClick={onCancel}
            sx={{ width: theme.spacing(10) }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
            sx={{ width: theme.spacing(10) }}>
            Confirm
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};
