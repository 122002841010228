import { Delete, Edit, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useDeleteHolidayMutation } from 'hooks/useDeleteHolidayMutation';
import { useSnackbar } from 'notistack';
import { HolidayWithShifts } from 'pages/scheduleConfig/Holidays';
import { useAuth } from 'providers/AuthProvider';
import { Fragment, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { buttonSize } from 'util/styling';
import { DeleteDialog } from './DeleteDialog';

export interface HolidayAccordionProps {
  holiday: HolidayWithShifts;
  setHolidayUpdate: (holiday: HolidayWithShifts) => void;
}

export const HolidayAccordion: React.FC<HolidayAccordionProps> = ({
  holiday,
  setHolidayUpdate,
}) => {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const { isAdmin } = useAuth();
  const deleteHolidayMutation = useDeleteHolidayMutation();

  const [isHidden, setIsHidden] = useState(true);
  const [showDeleteModal, hideDeleteModal] = useModal(() => (
    <DeleteDialog
      onConfirm={async () => {
        try {
          await deleteHolidayMutation.mutateAsync(holiday.id);
          snackbar.enqueueSnackbar('Holiday deleted successfully', {
            variant: 'success',
          });
        } catch {
          snackbar.enqueueSnackbar('Failed to delete holiday', {
            variant: 'error',
          });
        }
        hideDeleteModal();
      }}
      onCancel={hideDeleteModal}
      itemName="holiday"
    />
  ));

  const buttonDisplay: SxProps = {
    visibility: isHidden ? 'hidden' : 'visible',
  };

  const buttonStyle = buttonSize(theme);

  const holidayShifts = holiday.shifts;
  const shiftsString =
    holidayShifts.map((shift) => shift.shift).join(', ') ?? 'N/A';
  const pointsMin = Math.min(...holidayShifts.map((shift) => shift.points));
  const pointsMax = Math.max(...holidayShifts.map((shift) => shift.points));
  const pointsString =
    holidayShifts.length > 0 ? `${pointsMin}-${pointsMax}` : 'N/A';

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        onMouseOver={() => setIsHidden(false)}
        onMouseOut={() => setIsHidden(true)}>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Typography variant="body1">{holiday.date}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1">{holiday.name}</Typography>
          </Grid>
          <Grid item xs={3} md={4}>
            <Typography variant="body1">{shiftsString}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">{pointsString}</Typography>
          </Grid>
          {isAdmin && (
            <Grid item xs={2} md={1}>
              <Button
                sx={{
                  ...buttonStyle,
                  ...buttonDisplay,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setHolidayUpdate(holiday);
                }}>
                <Edit color="primary" />
              </Button>
              <Button
                sx={{
                  ...buttonStyle,
                  ...buttonDisplay,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  showDeleteModal();
                }}>
                <Delete color="primary" />
              </Button>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid
          container
          display="flex"
          sx={{ maxWidth: theme.spacing(50), marginRight: 'auto' }}>
          {holiday.shifts.length > 0 ? (
            holiday.shifts.map((shift) => (
              <Fragment key={shift.shiftId}>
                <Grid item xs={8} pl={4}>
                  {shift.shift}
                </Grid>
                <Grid item xs={4}>
                  {shift.points}
                </Grid>
              </Fragment>
            ))
          ) : (
            <Grid item xs={12} lg={6} px={4}>
              None
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
