import React, { createContext, useContext } from 'react';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth as FirebaseAuth, getAuth } from 'firebase/auth';
import {
  Analytics as FirebaseAnalytics,
  getAnalytics,
} from 'firebase/analytics';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyAo1q_j_gOiWTitUstU-oeswE3AlYwIwC4',
  authDomain: 'iwaa-c8a46.firebaseapp.com',
  projectId: 'iwaa-c8a46',
  messagingSenderId: '1014489455787',
  appId: '1:1014489455787:web:58151bdc60e519f5f36bee',
  measurementId: 'G-6F6X9T0Y39',
};

export const App = initializeApp(firebaseConfig);
export const Auth = getAuth(App);
export const Analytics = getAnalytics(App);
export const Storage = getStorage(App);

export type FirebaseContextType = {
  app: FirebaseApp;
  analytics: FirebaseAnalytics;
  auth: FirebaseAuth;
};

const FirebaseContext = createContext<FirebaseContextType>({
  app: App,
  analytics: Analytics,
  auth: Auth,
});

export const FirebaseProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <FirebaseContext.Provider
      value={{
        app: App,
        analytics: Analytics,
        auth: Auth,
      }}>
      {children}
    </FirebaseContext.Provider>
  );
};
export const useFirebase = () => useContext(FirebaseContext);
