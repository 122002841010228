import { Delete, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Grid,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';

import { ShiftForm } from 'components/forms/ShiftForm';
import { Shift } from 'openapi';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { DeleteDialog } from './DeleteDialog';
import { useSnackbar } from 'notistack';
import { useUpdateStandardShiftMutation } from 'hooks/useUpdateStandardShiftMutation';
import { useUpdateShiftMutation } from 'hooks/useUpdateShiftMutation';
import { useDeleteShiftMutation } from 'hooks/useDeleteShiftMutation';
import { buttonSize } from 'util/styling';

function getPointsString(shift: Shift) {
  const points: Array<number> = [];

  if (shift.sunday) {
    points.push(shift.sundaypoints);
  }
  if (shift.monday) {
    points.push(shift.mondaypoints);
  }
  if (shift.tuesday) {
    points.push(shift.tuesdaypoints);
  }
  if (shift.wednesday) {
    points.push(shift.wednesdaypoints);
  }
  if (shift.thursday) {
    points.push(shift.thursdaypoints);
  }
  if (shift.fridaypoints) {
    points.push(shift.fridaypoints);
  }
  if (shift.saturday) {
    points.push(shift.saturdaypoints);
  }

  if (points.length <= 0) {
    return 'Points: N/A';
  }

  const nonTrivialPoints = points.filter((x) => x > 0);

  if (nonTrivialPoints.length <= 0) {
    return 'N/A';
  }

  const minNumber = Math.min(...nonTrivialPoints);
  const maxNumber = Math.max(...nonTrivialPoints);

  return minNumber === maxNumber
    ? `${minNumber}`
    : `${minNumber} - ${maxNumber}`;
}

function getDaysString(shift: Shift) {
  const days: Array<number> = [];

  if (shift.monday) {
    days.push(0);
  }
  if (shift.tuesday) {
    days.push(1);
  }
  if (shift.wednesday) {
    days.push(2);
  }
  if (shift.thursday) {
    days.push(3);
  }
  if (shift.friday) {
    days.push(4);
  }
  if (shift.saturday) {
    days.push(5);
  }
  if (shift.sunday) {
    days.push(6);
  }

  const ranges: Array<{ min: number; max: number }> = [];

  days.forEach((day) => {
    if (ranges.length === 0) {
      ranges.push({ min: day, max: day });
    } else if (ranges[ranges.length - 1].max + 1 === day) {
      ranges[ranges.length - 1].max = day;
    } else {
      ranges.push({ min: day, max: day });
    }
  });

  if (ranges.length <= 0) {
    return 'None';
  }

  const daysOfWeek = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su'];

  return ranges
    .map((range) => {
      if (range.min === range.max) {
        return daysOfWeek[range.min];
      } else {
        return `${daysOfWeek[range.min]}-${daysOfWeek[range.max]}`;
      }
    })
    .join(', ');
}

export interface ShiftAccordionProps {
  shift: Shift;
}

export const ShiftAccordion: React.FC<ShiftAccordionProps> = ({ shift }) => {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const deleteShiftMutation = useDeleteShiftMutation();

  const [isHidden, setIsHidden] = useState(true);
  const [showModal, hideModal] = useModal(() => (
    <DeleteDialog
      onConfirm={async () => {
        try {
          await Promise.all([deleteShiftMutation.mutateAsync(shift.id)]);
          snackbar.enqueueSnackbar('Shift deleted successfully', {
            variant: 'success',
          });
          hideModal();
        } catch {
          snackbar.enqueueSnackbar('Failed to delete shift', {
            variant: 'error',
          });
        }
      }}
      onCancel={hideModal}
      itemName={'shift'}
    />
  ));

  const checkStandardMutation = useUpdateStandardShiftMutation();
  const updateShiftMutation = useUpdateShiftMutation();

  const buttonDisplay: SxProps = {
    visibility: isHidden ? 'hidden' : 'visible',
  };

  const buttonStyle = buttonSize(theme);

  const updateShift = async (shift: Shift) => {
    try {
      await updateShiftMutation.mutateAsync(shift);
      snackbar.enqueueSnackbar('Shift updated successfully', {
        variant: 'success',
      });
    } catch {
      snackbar.enqueueSnackbar('Shift failed to update', {
        variant: 'error',
      });
    }
  };

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        onMouseOver={() => setIsHidden(false)}
        onMouseOut={() => setIsHidden(true)}>
        <Grid container alignItems="center">
          <Grid item xs={2} md={1}>
            <Checkbox
              checked={shift.standard}
              onClick={(event) => event.stopPropagation()}
              onChange={async (event) => {
                await checkStandardMutation.mutateAsync({
                  shift,
                  standard: event.target.checked,
                });
              }}
              sx={{ padding: 0 }}
            />
          </Grid>
          <Grid item xs={4} md={5}>
            <Typography variant="body1">{shift.description}</Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography variant="body1">{getPointsString(shift)}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography variant="body1">{getDaysString(shift)}</Typography>
          </Grid>
          <Grid item xs={2} md={1}>
            <Button
              sx={{
                ...buttonStyle,
                ...buttonDisplay,
              }}
              onClick={(event) => {
                event.stopPropagation();
                showModal();
              }}>
              <Delete color="primary" />
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid item xs={12} lg={6}>
          <ShiftForm shift={shift} onSubmit={updateShift} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
