import { useMutation, useQueryClient } from 'react-query';
import { dateToString } from 'util/date';

import { ScheduleService } from 'openapi';

export const usePublishMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (date: Date) => {
      return ScheduleService.scheduleServicePublishSchedule(dateToString(date));
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['schedule'] });
      queryClient.refetchQueries({ queryKey: ['publishDate'] });
    },
  });
};
