import { useEffect } from 'react';

export const useNewRelic = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const newRelicTag = document.createElement('script');
      newRelicTag.src = '/newRelic.js';
      newRelicTag.async = true;
      document.head.appendChild(newRelicTag);
    }
  });
};
