import { useMutation, useQueryClient } from 'react-query';
import { UsersService } from 'openapi';

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userId: string) => {
      return UsersService.usersServiceDeleteUser(userId);
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['users'] });
    },
  });
};
