import { Box, Button } from '@mui/material';
import { useAuth } from 'providers/AuthProvider';

export const SimpleLogoutBar = () => {
  const { logout } = useAuth();
  return (
    <Box p={4} display="flex" justifyContent="right">
      <Button variant="contained" onClick={() => logout()}>
        Sign Out
      </Button>
    </Box>
  );
};
