import { ScheduleConfigService } from 'openapi';
import { useMutation, useQueryClient } from 'react-query';

export const useVacationRequestApproveMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (requestId: number) => {
      return ScheduleConfigService.scheduleConfigServiceApproveVacationRequest(
        requestId,
      );
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['requests'] });
      queryClient.refetchQueries({ queryKey: ['schedule'] });
    },
  });
};
