import { useMutation, useQueryClient } from 'react-query';

import { ScheduleConfigService } from 'openapi';
import { HolidayWithShifts } from 'pages/scheduleConfig/Holidays';

export const useUpdateHolidayMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (holiday: HolidayWithShifts) => {
      await ScheduleConfigService.scheduleConfigServiceUpdateHoliday(
        holiday.id,
        holiday,
      );
      await ScheduleConfigService.scheduleConfigServiceUpdateHolidayShifts(
        holiday.id,
        holiday.shifts,
      );
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['holidays'] });
      queryClient.refetchQueries({ queryKey: ['holidaysWithShifts'] });
      queryClient.refetchQueries({ queryKey: ['schedule'] });
    },
  });
};
