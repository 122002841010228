import { Add, Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { Loading } from 'components/atoms/Loading';
import { HolidayForm } from 'components/forms/HolidayForm';
import { HolidayAccordion } from 'components/molecules/HolidayAccordion';

import { MainCard } from 'components/molecules/MainCard';
import { PeriodHeader } from 'components/molecules/PeriodHeader';
import { useCreateHolidayMutation } from 'hooks/useCreateHolidayMutation';
import { useUpdateHolidayMutation } from 'hooks/useUpdateHolidayMutation';
import { useSnackbar } from 'notistack';
import { Holiday, HolidayShift, ScheduleConfigService } from 'openapi';
import { useAuth } from 'providers/AuthProvider';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useQuery } from 'react-query';
import { dateToString } from 'util/date';
import { SchedulePeriod } from 'util/enum';

export interface HolidayWithShifts extends Holiday {
  shifts: Array<HolidayShift>;
}

interface YearCardProps {
  holidays: Array<HolidayWithShifts>;
}

export const YearCard: React.FC<YearCardProps> = ({ holidays }) => {
  const theme = useTheme();
  const snackbar = useSnackbar();

  const { isAdmin } = useAuth();

  const defaultHoliday: HolidayWithShifts = {
    id: 0,
    date: dateToString(new Date()),
    name: 'New holiday',
    workable: true,
    shifts: [],
  };

  const [holidayUpdate, setHolidayUpdate] = useState<HolidayWithShifts | null>(
    null,
  );

  const createHolidayMutation = useCreateHolidayMutation();
  const updateHolidayMutation = useUpdateHolidayMutation();

  const createHoliday = async (holiday: HolidayWithShifts) => {
    try {
      await createHolidayMutation.mutateAsync(holiday);
      snackbar.enqueueSnackbar('Holiday created successfully', {
        variant: 'success',
      });
    } catch {
      snackbar.enqueueSnackbar('Failed to create holiday', {
        variant: 'error',
      });
    }
    hideModal();
  };

  const updateHoliday = async (holiday: HolidayWithShifts) => {
    try {
      await updateHolidayMutation.mutateAsync(holiday);
      snackbar.enqueueSnackbar('Holiday updated successfully', {
        variant: 'success',
      });
    } catch {
      snackbar.enqueueSnackbar('Failed to update holiday', {
        variant: 'error',
      });
    }
    hideEditModal();
  };

  const [showModal, hideModal] = useModal(() => {
    return (
      <Dialog open={true} fullWidth maxWidth="md">
        <Grid container justifyContent="right">
          <Button onClick={hideModal}>
            <Close />
          </Button>
        </Grid>
        <Typography variant="h2" textAlign="center">
          Create Holiday
        </Typography>
        <Box
          py={4}
          sx={{ minWidth: theme.spacing(90), maxWidth: theme.spacing(120) }}>
          <HolidayForm
            mode="create"
            holiday={defaultHoliday}
            onSubmit={createHoliday}
            onCancel={hideModal}
          />
        </Box>
      </Dialog>
    );
  });

  const [showEditModal, hideEditModal] = useModal(() => {
    return holidayUpdate ? (
      <Dialog open={true} fullWidth maxWidth="md">
        <Grid container justifyContent="right">
          <Button onClick={hideEditModal}>
            <Close />
          </Button>
        </Grid>
        <Typography variant="h2" textAlign="center">
          Update Holiday
        </Typography>
        <Box
          py={4}
          sx={{ minWidth: theme.spacing(90), maxWidth: theme.spacing(120) }}>
          <HolidayForm
            mode="update"
            holiday={holidayUpdate}
            onSubmit={updateHoliday}
            onCancel={hideEditModal}
          />
        </Box>
      </Dialog>
    ) : null;
  }, [holidayUpdate]);

  const onUpdateHoliday = (holiday: HolidayWithShifts) => {
    setHolidayUpdate(holiday);
    showEditModal();
  };

  return (
    <MainCard>
      <Grid container alignItems="baseline" pl={2} pr={5} mb={2}>
        <Grid item xs={2}>
          <Typography variant="h4">Date</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h4">Holiday</Typography>
        </Grid>
        <Grid item xs={3} md={4}>
          <Typography variant="h4">Shifts</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4">Points</Typography>
        </Grid>
        {isAdmin && (
          <Grid item xs={2} md={1}>
            <Button variant="contained" size="small" onClick={showModal}>
              <Add />
              New
            </Button>
          </Grid>
        )}
      </Grid>
      {holidays.length > 0
        ? holidays.map((holiday) => (
            <div key={holiday.id}>
              <HolidayAccordion
                holiday={holiday}
                setHolidayUpdate={onUpdateHoliday}
              />
              <Divider />
            </div>
          ))
        : 'No holidays have been set up for this year.'}
    </MainCard>
  );
};

export const Holidays: React.FC = () => {
  const [date, setDate] = useState<Date>(new Date());
  const year = date.getFullYear();

  const { isLoading, data: holidays } = useQuery(
    ['holidaysWithShifts', year],
    async () => {
      const holidays =
        await ScheduleConfigService.scheduleConfigServiceGetHolidays(year);
      // admittedly, we have a little of an N+1 issue here but not too bad;
      // probably should have designed the API differently for this
      const holidaysWithShifts = await Promise.all(
        holidays.map(async (holiday) => {
          const shifts = (
            await ScheduleConfigService.scheduleConfigServiceGetHolidayShifts(
              holiday.id,
            )
          ).sort((a, b) => a.shiftRank - b.shiftRank);
          return { ...holiday, shifts };
        }),
      );
      return holidaysWithShifts.sort((a, b) => a.date.localeCompare(b.date));
    },
  );

  return (
    <Grid item xs={12}>
      <Typography variant="h1" textAlign="center">
        Holidays
      </Typography>
      <Box m={4} />
      <PeriodHeader
        period={SchedulePeriod.Annually}
        startDate={date}
        onSelectDate={setDate}
      />
      {holidays ? (
        <YearCard holidays={holidays} />
      ) : isLoading ? (
        <Loading />
      ) : (
        <MainCard>No holidays have been published for this year.</MainCard>
      )}
      <Box m={4} />
    </Grid>
  );
};
