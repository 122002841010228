import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';

import { useAuth } from 'providers/AuthProvider';

// Adapted from berrydashboard.io
export const LoginForm = () => {
  const theme = useTheme();
  const { login } = useAuth();

  const [isRememberMeChecked, setRememberMeChecked] = useState(true);
  const [isShowPasswordChecked, setShowPasswordChecked] = useState(false);

  const handleClickShowPassword = () => {
    setShowPasswordChecked(!isShowPasswordChecked);
  };

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.email, values.password);
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setSubmitting(false);
          if (err instanceof Error) {
            setErrors({ submit: err.message });
          }
        }
      }}>
      {({ isSubmitting }) => (
        <Form noValidate>
          <Field
            name="email"
            component={TextField}
            variant="outlined"
            type="email"
            label="Email Address / Username"
            autoComplete="username"
            sx={{ width: '100%', ...theme.typography.customInput }}
          />

          <Field
            name="password"
            component={TextField}
            variant="outlined"
            type={isShowPasswordChecked ? 'text' : 'password'}
            label="Password"
            autoComplete="current-password"
            sx={{ width: '100%', ...theme.typography.customInput }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large">
                    {isShowPasswordChecked ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRememberMeChecked}
                  onChange={(event) =>
                    setRememberMeChecked(event.target.checked)
                  }
                  name="checked"
                  color="primary"
                />
              }
              label="Remember me"
            />
            <Typography
              variant="subtitle1"
              component={Link}
              to={'/login/forgot-password'}
              color="secondary"
              sx={{ textDecoration: 'none' }}>
              Forgot Password?
            </Typography>
          </Stack>

          <ErrorMessage name="submit">
            {(msg) => <FormHelperText error>{msg}</FormHelperText>}
          </ErrorMessage>

          <Box sx={{ mt: 2 }}>
            <Button
              disableElevation
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="secondary">
              Sign in
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
