// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormHelperText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// third party
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';

// project imports
import { useAuth } from 'providers/AuthProvider';
import { useSnackbar } from 'notistack';
import { TextField } from 'formik-mui';

// Adapted from berrydashboard.io
export const ForgotPasswordForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { resetPassword } = useAuth();

  return (
    <Formik
      initialValues={{
        email: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await resetPassword(values.email);

          setStatus({ success: true });
          setSubmitting(false);
          setTimeout(() => {
            navigate('/login', { replace: true });
          }, 1500);
          enqueueSnackbar('Check mail for reset password link', {
            variant: 'success',
          });
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setSubmitting(false);
          if (err instanceof Error) {
            setErrors({ submit: err.message });
          }
          enqueueSnackbar(
            'Password reset failed. Contact the site administrator.',
            {
              variant: 'error',
            },
          );
        }
      }}>
      {({ isSubmitting }) => (
        <Form noValidate>
          <Field
            name="email"
            component={TextField}
            variant="outlined"
            type="email"
            label="Email Address / Username"
            autoComplete="username"
            sx={{ width: '100%', ...theme.typography.customInput }}
          />

          <ErrorMessage name="submit">
            {(msg) => <FormHelperText error>{msg}</FormHelperText>}
          </ErrorMessage>

          <Box sx={{ mt: 2 }}>
            <Button
              disableElevation
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="secondary">
              Send Mail
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
