import {
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isTuesday,
  isThursday,
  isWednesday,
  eachDayOfInterval,
} from 'date-fns';
import { Assignment, Shift, UnpublishedAssignment } from 'openapi';
import { convertDateFromUTC, dateToString } from './date';

export const createAssignmentsFromShift = (
  beginDate: Date,
  endDate: Date,
  shift: Shift,
  existing?: Assignment[],
) => {
  const unpublished: Array<UnpublishedAssignment> = [];

  eachDayOfInterval({ start: beginDate, end: endDate }).forEach((date) => {
    const stringDate = dateToString(date);
    const baseAssignment: Pick<Assignment, 'shiftId' | 'date'> = {
      date: dateToString(date),
      shiftId: shift.id,
    };
    if (
      !existing ||
      existing.findIndex((assignment) => assignment.date === stringDate) < 0
    ) {
      if (isMonday(date) && shift.monday) {
        unpublished.push({
          ...baseAssignment,
          points: shift.mondaypoints,
        });
      } else if (isTuesday(date) && shift.tuesday) {
        unpublished.push({
          ...baseAssignment,
          points: shift.tuesdaypoints,
        });
      } else if (isWednesday(date) && shift.wednesday) {
        unpublished.push({
          ...baseAssignment,
          points: shift.wednesdaypoints,
        });
      } else if (isThursday(date) && shift.thursday) {
        unpublished.push({
          ...baseAssignment,
          points: shift.thursdaypoints,
        });
      } else if (isFriday(date) && shift.friday) {
        unpublished.push({
          ...baseAssignment,
          points: shift.fridaypoints,
        });
      } else if (isSaturday(date) && shift.saturday) {
        unpublished.push({
          ...baseAssignment,
          points: shift.saturdaypoints,
        });
      } else if (isSunday(date) && shift.sunday) {
        unpublished.push({
          ...baseAssignment,
          points: shift.sundaypoints,
        });
      }
    }
  });

  return unpublished;
};

export const deleteAssignments = (shift: Shift, assignments: Assignment[]) => {
  const unpublished: Array<UnpublishedAssignment> = [];
  assignments.forEach((assignment) => {
    const dateObject = convertDateFromUTC(new Date(assignment.date));
    if (
      (isMonday(dateObject) && !shift.monday) ||
      (isTuesday(dateObject) && !shift.tuesday) ||
      (isWednesday(dateObject) && !shift.wednesday) ||
      (isThursday(dateObject) && !shift.thursday) ||
      (isFriday(dateObject) && !shift.friday) ||
      (isSaturday(dateObject) && !shift.saturday) ||
      (isSunday(dateObject) && !shift.sunday)
    ) {
      unpublished.push({ ...assignment, id: undefined, toBeDeleted: true });
    }
  });
  return unpublished;
};

export const updateAssignmentPoints = (
  shift: Shift,
  assignments: Assignment[],
) => {
  const unpublished: Array<UnpublishedAssignment> = [];
  assignments.forEach((assignment) => {
    const dateObject = convertDateFromUTC(new Date(assignment.date));
    if (isMonday(dateObject) && shift.monday) {
      if (assignment.points !== shift.mondaypoints) {
        unpublished.push({
          ...assignment,
          points: shift.mondaypoints,
        });
      }
    } else if (isTuesday(dateObject) && shift.tuesday) {
      if (assignment.points !== shift.tuesdaypoints) {
        unpublished.push({
          ...assignment,
          points: shift.tuesdaypoints,
        });
      }
    } else if (isWednesday(dateObject) && shift.wednesday) {
      if (assignment.points !== shift.wednesdaypoints) {
        unpublished.push({
          ...assignment,
          points: shift.wednesdaypoints,
        });
      }
    } else if (isThursday(dateObject) && shift.thursday) {
      if (assignment.points !== shift.thursdaypoints) {
        unpublished.push({
          ...assignment,
          points: shift.thursdaypoints,
        });
      }
    } else if (isFriday(dateObject) && shift.friday) {
      if (assignment.points !== shift.fridaypoints) {
        unpublished.push({
          ...assignment,
          points: shift.fridaypoints,
        });
      }
    } else if (isSaturday(dateObject) && shift.saturday) {
      if (assignment.points !== shift.saturdaypoints) {
        unpublished.push({
          ...assignment,
          points: shift.saturdaypoints,
        });
      }
    } else if (isSunday(dateObject) && shift.sunday) {
      if (assignment.points !== shift.sundaypoints) {
        unpublished.push({
          ...assignment,
          points: shift.sundaypoints,
        });
      }
    }
  });
  return unpublished;
};

export const selectPointsFromShift = (date: Date, shift: Shift) => {
  if (isMonday(date)) {
    return shift.mondaypoints;
  } else if (isTuesday(date)) {
    return shift.tuesdaypoints;
  } else if (isWednesday(date)) {
    return shift.wednesdaypoints;
  } else if (isThursday(date)) {
    return shift.thursdaypoints;
  } else if (isFriday(date)) {
    return shift.fridaypoints;
  } else if (isSaturday(date)) {
    return shift.saturdaypoints;
  } else if (isSaturday(date)) {
    return shift.sundaypoints;
  }
  // otherwise return reasonable default
  return 2;
};
