import { Paper } from '@mui/material';

export interface CoverProps {
  imageUrl: string;
  children: React.ReactNode;
}

export const Cover = ({ imageUrl, children }: CoverProps) => {
  return (
    <Paper
      square
      sx={{
        minHeight: '100vh',
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
      {children}
    </Paper>
  );
};
