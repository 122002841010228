import {
  useTheme,
  Button,
  FormLabel,
  Grid,
  MenuItem,
  Stack,
} from '@mui/material';
import { InlineFormActions } from 'components/molecules/InlineFormActions';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import { useMediaSize } from 'hooks/useMediaSize';
import { AuthUser } from 'openapi';
import { useAuth } from 'providers/AuthProvider';
import { useState } from 'react';
import * as Yup from 'yup';

export interface UserFormProps {
  mode?: 'inline' | 'modal';
  user: AuthUser;
  onSubmit: (user: AuthUser) => void;
  onCancel?: () => void;
}

const UserSchema = Yup.object().shape({
  user: Yup.object({
    displayName: Yup.string()
      .min(2, 'Please enter a display name')
      .max(50, 'Please enter a name no longer than 50 characters')
      .required('Display name is required'),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    role: Yup.string().required('Access Level is required'),
  }),
});

export const UserForm: React.FC<UserFormProps> = ({
  mode,
  user,
  onSubmit,
  onCancel,
}: UserFormProps) => {
  const theme = useTheme();
  const mediaSize = useMediaSize();
  const labelAlign = mediaSize === 'XS' ? 'left' : 'center';
  const isInline = mode ? mode !== 'modal' : true;
  const disableHelper = { sx: { display: 'none' } };
  const [isViewOnly, setViewOnly] = useState(isInline);

  const { isAdmin } = useAuth();

  return (
    <Formik
      initialValues={{
        user,
        onSubmit,
      }}
      onSubmit={async (values) => {
        onSubmit(values.user);
        setViewOnly(true);
      }}
      validationSchema={UserSchema}>
      {() => (
        <Form noValidate>
          {isInline && isAdmin && (
            <InlineFormActions
              isViewOnly={isViewOnly}
              setViewOnly={setViewOnly}
            />
          )}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3} textAlign={labelAlign}>
              <FormLabel>Display Name</FormLabel>
            </Grid>
            <Grid item xs={12} md={9}>
              <Field
                name="user.displayName"
                component={TextField}
                label="Display Name"
                disabled={isViewOnly}
                FormHelperTextProps={disableHelper}
              />
            </Grid>

            <Grid item xs={12} md={3} textAlign={labelAlign}>
              <FormLabel>Access Level</FormLabel>
            </Grid>
            <Grid item xs={12} md={9}>
              <Field
                name="user.role"
                component={Select}
                label="Access Level"
                formControl={{
                  FormHelperTextProps: disableHelper,
                }}
                disabled={isViewOnly}>
                <MenuItem value={'partner'}>Partner</MenuItem>
                <MenuItem value={'admin'}>Admin</MenuItem>
                <MenuItem value={'hospital'}>Hospital</MenuItem>
              </Field>
            </Grid>

            <Grid item xs={12} md={3} textAlign={labelAlign}>
              <FormLabel>Email</FormLabel>
            </Grid>
            <Grid item xs={12} md={9}>
              <Field
                name="user.email"
                component={TextField}
                label="Email"
                disabled={isViewOnly}
              />
            </Grid>
            {!isInline && (
              <Stack direction="row" pt={4} spacing={2} marginLeft="auto">
                <Button
                  variant="outlined"
                  onClick={onCancel}
                  sx={{ width: theme.spacing(10) }}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ width: theme.spacing(10) }}>
                  Create
                </Button>
              </Stack>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
