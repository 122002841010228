import { Delete } from '@mui/icons-material';
import { Box, Button, FormLabel, Grid, Stack, useTheme } from '@mui/material';
import { MultiFieldError } from 'components/atoms/MultiFieldError';
import { ShiftSelectAdd } from 'components/organisms/ShiftSelectAdd';
import { Field, FieldArray, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { MobileDatePicker as DatePicker } from 'formik-mui-x-date-pickers';
import { HolidayWithShifts } from 'pages/scheduleConfig/Holidays';
import { useAuth } from 'providers/AuthProvider';
import { Fragment } from 'react';
import { convertDateFromUTC, dateToString } from 'util/date';
import { selectPointsFromShift } from 'util/shiftAssignments';
import { buttonSize } from 'util/styling';
import * as Yup from 'yup';

export interface HolidayFormProps {
  mode: 'create' | 'update';
  holiday: HolidayWithShifts;
  onSubmit: (holiday: HolidayWithShifts) => void;
  onCancel?: () => void;
}

const HolidaySchema = Yup.object().shape({
  holiday: Yup.object({
    name: Yup.string()
      .min(1, 'Please enter a holiday name')
      .max(25, 'Holiday name should not be longer than 25 characters')
      .required('Holiday name is required'),
    date: Yup.date().required('Holiday date is required'),
  }),
});

export const HolidayForm: React.FC<HolidayFormProps> = ({
  mode,
  holiday,
  onSubmit,
  onCancel,
}: HolidayFormProps) => {
  const theme = useTheme();

  const { isAdmin } = useAuth();
  const canEdit = isAdmin;

  const disableHelper = { sx: { display: 'none' } };
  const helperTextRow = { sx: { mt: theme.spacing(-2) } };

  const buttonStyle = buttonSize(theme);

  const shiftsInUse = holiday.shifts.map((shift) => shift.shiftId);
  const holidayWithDate = {
    ...holiday,
    date: convertDateFromUTC(new Date(holiday.date)),
  };

  return (
    <Formik
      initialValues={{
        holiday: holidayWithDate,
      }}
      onSubmit={async (values) => {
        const holiday: HolidayWithShifts = {
          ...values.holiday,
          date: dateToString(values.holiday.date),
        };
        onSubmit(holiday);
      }}
      validationSchema={HolidaySchema}>
      {(formik) => (
        <Form noValidate>
          <Grid
            container
            columnSpacing={1}
            rowSpacing={2}
            alignItems="center"
            sx={{ maxWidth: 720, marginLeft: 'auto', marginRight: 'auto' }}>
            <Grid container item rowSpacing={2} alignItems="center">
              <Grid item xs={12} sm={2} textAlign="center">
                <FormLabel>Date</FormLabel>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Field
                  name="holiday.date"
                  component={DatePicker}
                  label="Date"
                  disabled={!canEdit}
                  FormHelperTextProps={disableHelper}
                />
              </Grid>

              <Grid item xs={12} sm={2} textAlign="center">
                <FormLabel>Name</FormLabel>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="holiday.name"
                  component={TextField}
                  label="Name"
                  disabled={!canEdit}
                  FormHelperTextProps={disableHelper}
                />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={10} {...helperTextRow} alignSelf="left">
                <MultiFieldError fields={['holiday.date', 'holiday.name']} />
              </Grid>

              <Grid item xs={2} />
              <Grid item xs={6}>
                <FormLabel>Shift</FormLabel>
              </Grid>
              <Grid item xs={3} px={2}>
                <FormLabel>Points</FormLabel>
              </Grid>
              <Grid item xs={1} />

              <FieldArray
                name="holiday.shifts"
                render={(arrayHelpers) =>
                  formik.values.holiday.shifts
                    .map((shift, index) => (
                      <Fragment key={index}>
                        <Grid item xs={2} />
                        <Grid item xs={6}>
                          <Field
                            name={`holiday.shifts.${index}.shift`}
                            component={TextField}
                            disabled
                            FormHelperTextProps={disableHelper}
                          />
                        </Grid>
                        <Grid item xs={3} px={2}>
                          <Field
                            name={`holiday.shifts.${index}.points`}
                            component={TextField}
                            FormHelperTextProps={disableHelper}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Button
                            sx={{
                              ...buttonStyle,
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              arrayHelpers.remove(index);
                            }}>
                            <Delete color="primary" />
                          </Button>
                        </Grid>
                      </Fragment>
                    ))
                    .concat(
                      <Fragment key="add">
                        <Grid item xs={2} />
                        <Grid item xs={4} alignItems="baseline">
                          <ShiftSelectAdd
                            shiftsToExclude={shiftsInUse}
                            onSelectShift={(shift) => {
                              arrayHelpers.insert(
                                formik.values.holiday.shifts.length,
                                {
                                  shift: shift.description,
                                  shiftId: shift.id,
                                  holiday: formik.values.holiday.name,
                                  holidayId: formik.values.holiday.id,
                                  points: selectPointsFromShift(
                                    formik.values.holiday.date,
                                    shift,
                                  ),
                                },
                              );
                            }}
                          />
                        </Grid>
                      </Fragment>,
                    )
                }
              />
            </Grid>
            <Box pb={4} />
            <Stack direction="row" pt={4} spacing={2} marginLeft="auto">
              <Button
                variant="outlined"
                onClick={onCancel}
                sx={{ width: theme.spacing(10) }}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{ width: theme.spacing(10) }}>
                {mode === 'create' ? 'Create' : 'Update'}
              </Button>
            </Stack>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
