import { useMediaQuery, useTheme } from '@mui/material';

// this is to be used as a debugging tool
export const useMediaSize = () => {
  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.up('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.up('xs'));

  return isXLarge
    ? 'XL'
    : isLarge
      ? 'L'
      : isMedium
        ? 'M'
        : isSmall
          ? 'S'
          : isXSmall
            ? 'XS'
            : '???';
};
