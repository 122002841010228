import { FormLabel, Grid, Typography } from '@mui/material';
import { TextField, Checkbox } from 'formik-mui';
import { Field } from 'formik';
import * as Yup from 'yup';

import { useMediaSize } from 'hooks/useMediaSize';

export interface ShiftPointsSubformProps {
  isViewOnly?: boolean;
}

const shiftPoints = Yup.number()
  .min(0, 'Please enter a non negative number')
  .max(25, 'Please enter a number below 25');

export const ShiftPointsSchema = Yup.object({
  mondaypoints: shiftPoints,
  mondaycutpoints: shiftPoints,
  tuesdaypoints: shiftPoints,
  tuesdaycutpoints: shiftPoints,
  wednesdaypoints: shiftPoints,
  wednesdaycutpoints: shiftPoints,
  thursdaypoints: shiftPoints,
  thursdaycutpoints: shiftPoints,
  fridaypoints: shiftPoints,
  fridaycutpoints: shiftPoints,
  saturdaypoints: shiftPoints,
  saturdaycutpoints: shiftPoints,
  sundaypoints: shiftPoints,
  sundaycutpoints: shiftPoints,
});

export const ShiftPointsSubform: React.FC<ShiftPointsSubformProps> = ({
  isViewOnly,
}) => {
  const mediaSize = useMediaSize();
  const labelAlign = mediaSize === 'XS' ? 'left' : 'center';

  const disableHelper = { sx: { display: 'none' } };

  return (
    <>
      <Grid container item alignItems="center" columns={10} columnSpacing={2}>
        <Grid item sm={2} md={2} />
        <Grid item sm={1} md={1} display="flex" justifyContent="center">
          <Typography variant="body1">M</Typography>
        </Grid>
        <Grid item sm={1} md={1} display="flex" justifyContent="center">
          <Typography variant="body1">T</Typography>
        </Grid>
        <Grid item sm={1} md={1} display="flex" justifyContent="center">
          <Typography variant="body1">W</Typography>
        </Grid>
        <Grid item sm={1} md={1} display="flex" justifyContent="center">
          <Typography variant="body1">Th</Typography>
        </Grid>
        <Grid item sm={1} md={1} display="flex" justifyContent="center">
          <Typography variant="body1">F</Typography>
        </Grid>
        <Grid item sm={1} md={1} display="flex" justifyContent="center">
          <Typography variant="body1">S</Typography>
        </Grid>
        <Grid item sm={1} md={1} display="flex" justifyContent="center">
          <Typography variant="body1">S</Typography>
        </Grid>
      </Grid>

      <Grid container item alignItems="center" columns={10} columnSpacing={2}>
        <Grid item xs={12} sm={2} textAlign={labelAlign}>
          <FormLabel>Days</FormLabel>
        </Grid>
        <Grid item xs={1} sm={1} md={1} display="flex" justifyContent="center">
          <Field
            type="checkbox"
            name="shift.monday"
            component={Checkbox}
            label="Monday"
            disabled={isViewOnly}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} display="flex" justifyContent="center">
          <Field
            type="checkbox"
            name="shift.tuesday"
            component={Checkbox}
            label="Tuesday"
            disabled={isViewOnly}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} display="flex" justifyContent="center">
          <Field
            type="checkbox"
            name="shift.wednesday"
            component={Checkbox}
            label="Wednesday"
            disabled={isViewOnly}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} display="flex" justifyContent="center">
          <Field
            type="checkbox"
            name="shift.thursday"
            component={Checkbox}
            label="Thursday"
            disabled={isViewOnly}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} display="flex" justifyContent="center">
          <Field
            type="checkbox"
            name="shift.friday"
            component={Checkbox}
            label="Friday"
            disabled={isViewOnly}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} display="flex" justifyContent="center">
          <Field
            type="checkbox"
            name="shift.saturday"
            component={Checkbox}
            label="Saturday"
            disabled={isViewOnly}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} display="flex" justifyContent="center">
          <Field
            type="checkbox"
            name="shift.sunday"
            component={Checkbox}
            label="Sunday"
            disabled={isViewOnly}
          />
        </Grid>
      </Grid>
      <Grid container item alignItems="center" columns={10} columnSpacing={2}>
        <Grid item xs={12} sm={2} textAlign={labelAlign}>
          <FormLabel>Points</FormLabel>
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.mondaypoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.tuesdaypoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.wednesdaypoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.thursdaypoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.fridaypoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.saturdaypoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.sundaypoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
      </Grid>
      <Grid container item alignItems="center" columns={10} columnSpacing={2}>
        <Grid item xs={12} sm={2} textAlign={labelAlign}>
          <FormLabel>Cut Points</FormLabel>
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.mondaycutpoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.tuesdaycutpoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.wednesdaycutpoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.thursdaycutpoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.fridaycutpoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.saturdaycutpoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Field
            name="shift.sundaycutpoints"
            component={TextField}
            disabled={isViewOnly}
            FormHelperTextProps={disableHelper}
          />
        </Grid>
      </Grid>
    </>
  );
};
