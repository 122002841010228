export interface LogoProps {
  size?: 'small' | 'medium' | 'large';
}

export const Logo: React.FC<LogoProps> = ({ size }) => {
  // logos provided free by https://www.freepik.com/
  const logosBySize = {
    small: 'anesthesia-icon-32.png',
    medium: 'anesthesia-icon-64.png',
    large: 'anesthesia-icon-128.png',
  };
  const logo = (size && logosBySize[size]) ?? logosBySize['medium'];

  return <img src={`/${logo}`} alt="IWAA" width="100" />;
};
