import { Navigate } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';
import { MainLayout } from './templates/MainLayout';

export const AuthRequired: React.FC = () => {
  const { isAuthenticated } = useAuth();
  return (
    <div>
      {isAuthenticated ? <MainLayout /> : <Navigate to="/login" replace />}
    </div>
  );
};
