import { Delete, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';

import { ProfileForm } from 'components/forms/ProfileForm';
import { useSnackbar } from 'notistack';
import { AuthUser, Partner, UsersService } from 'openapi';
import { useMutation } from 'react-query';
import { buttonSize } from 'util/styling';
import { DeleteDialog } from './DeleteDialog';
import { useModal } from 'react-modal-hook';
import { useDeleteUserMutation } from 'hooks/useDeleteUserMutation';
import { useState } from 'react';
import { useAuth } from 'providers/AuthProvider';
import { useUpdatePartnerMutation } from 'hooks/useUpdatePartnerMutation';

export interface PartnerAccordionProps {
  partner: Partner;
  user: AuthUser;
}

export const PartnerAccordion: React.FC<PartnerAccordionProps> = ({
  partner,
  user,
}) => {
  const partnerMutatation = useUpdatePartnerMutation();
  const userMutation = useMutation((authUser: AuthUser) => {
    return UsersService.usersServiceUpdateUser(authUser.uid, authUser);
  });
  const snackbar = useSnackbar();
  const updatePartnerAndUser = async (partner: Partner, user: AuthUser) => {
    try {
      await Promise.all([
        partnerMutatation.mutateAsync(partner),
        userMutation.mutateAsync({
          uid: user.uid,
          displayName: `${partner.firstName} ${partner.lastName}`,
          email: partner.email,
          role: user.role,
        }),
      ]);

      snackbar.enqueueSnackbar('Partner updated successfully', {
        variant: 'success',
      });
    } catch {
      snackbar.enqueueSnackbar('Partner failed to update', {
        variant: 'error',
      });
    }
  };

  const [isHidden, setIsHidden] = useState(true);
  const theme = useTheme();
  const buttonStyle = buttonSize(theme);
  const buttonDisplay: SxProps = {
    visibility: isHidden ? 'hidden' : 'visible',
  };
  const deleteUserMutation = useDeleteUserMutation();

  const { isAdmin } = useAuth();

  const [showModal, hideModal] = useModal(() => (
    <DeleteDialog
      onConfirm={async () => {
        try {
          await Promise.all([deleteUserMutation.mutateAsync(user.uid)]);
          snackbar.enqueueSnackbar('User deleted successfully', {
            variant: 'success',
          });
          hideModal();
        } catch {
          snackbar.enqueueSnackbar('Failed to delete user', {
            variant: 'error',
          });
        }
      }}
      onCancel={hideModal}
      itemName={'partner'}
    />
  ));

  return (
    <Accordion key={partner.id} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        onMouseOver={() => setIsHidden(false)}
        onMouseOut={() => setIsHidden(true)}>
        <Grid container alignItems="center">
          <Grid item xs={8} md={3}>
            <Typography variant="body1">
              {partner.firstName + ' ' + partner.lastName}
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography variant="body1">{partner.initials}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body1">{partner.mobile}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body1">{partner.email}</Typography>
          </Grid>
          {isAdmin && (
            <Grid item xs={2} md={1}>
              <Button
                sx={{
                  ...buttonStyle,
                  ...buttonDisplay,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  showModal();
                }}>
                <Delete color="primary" />
              </Button>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid item xs={12} lg={6}>
          {user ? (
            <ProfileForm
              partner={partner}
              partnerUser={user}
              onSubmit={updatePartnerAndUser}
            />
          ) : (
            'No user was found for this partner'
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
