import { FormLabel, Grid, useTheme, Stack, Button } from '@mui/material';
import { InlineFormActions } from 'components/molecules/InlineFormActions';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useMediaSize } from 'hooks/useMediaSize';
import { Shift } from 'openapi';
import { useAuth } from 'providers/AuthProvider';
import { useState } from 'react';
import * as Yup from 'yup';
import { ShiftPointsSubform, ShiftPointsSchema } from './ShiftPointsSubform';

export interface ShiftFormProps {
  mode?: 'inline' | 'modal';
  shift: Shift;
  onSubmit: (shift: Shift) => void;
  onCancel?: () => void;
}

const ShiftSchema = Yup.object().shape({
  shift: Yup.object({
    description: Yup.string()
      .min(1, 'Please enter a description')
      .max(50, 'Shift should not be longer than 50 characters')
      .required('Description is required'),
  }).concat(ShiftPointsSchema),
});

export const ShiftForm: React.FC<ShiftFormProps> = ({
  mode,
  shift,
  onSubmit,
  onCancel,
}: ShiftFormProps) => {
  const mediaSize = useMediaSize();
  const theme = useTheme();
  const labelAlign = mediaSize === 'XS' ? 'left' : 'center';

  const isInline = mode ? mode !== 'modal' : true;
  const [isViewOnly, setViewOnly] = useState(isInline);

  const { authUser } = useAuth();
  const canEdit = authUser?.role === 'admin';

  return (
    <Formik
      initialValues={{
        shift,
      }}
      onSubmit={async (values) => {
        onSubmit(values.shift);
      }}
      validationSchema={ShiftSchema}>
      {() => (
        <Form noValidate>
          {isInline && canEdit && (
            <InlineFormActions
              isViewOnly={isViewOnly}
              setViewOnly={setViewOnly}
            />
          )}
          <Grid
            container
            columnSpacing={1}
            rowSpacing={2}
            alignItems="center"
            sx={{ maxWidth: 720, marginLeft: 'auto', marginRight: 'auto' }}>
            <Grid container item columns={10} alignItems="center">
              <Grid item xs={12} sm={2} textAlign={labelAlign}>
                <FormLabel>Description</FormLabel>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Field
                  name="shift.description"
                  component={TextField}
                  label="Description"
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            <ShiftPointsSubform isViewOnly={isViewOnly} />
            {!isInline && (
              <Stack direction="row" pt={4} spacing={2} marginLeft="auto">
                <Button
                  variant="outlined"
                  onClick={onCancel}
                  sx={{ width: theme.spacing(10) }}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ width: theme.spacing(10) }}>
                  Create
                </Button>
              </Stack>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
