import {
  Autocomplete,
  Button,
  ClickAwayListener,
  Grid,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ClickAwayPopper } from 'components/atoms/ClickAwayPopper';
import { useDeleteUserMutation } from 'hooks/useDeleteUserMutation';
import { useUpdateUserRoleMutation } from 'hooks/useUpdateUserRoleMutation';
import { useSnackbar } from 'notistack';
import { AuthUser } from 'openapi';
import { useState } from 'react';
import { buttonSize } from 'util/styling';
import { DeleteDialog } from './DeleteDialog';
import { Delete } from '@mui/icons-material';
import { useModal } from 'react-modal-hook';

export const UserRow: React.FC<{ user: AuthUser }> = ({ user }) => {
  interface RoleOption {
    label: string;
    id: string;
  }

  const roleOptions: Array<RoleOption> = [
    { label: 'Partner', id: 'partner' },
    { label: 'Admin', id: 'admin' },
    { label: 'Hospital', id: 'hospital' },
  ];

  const updateUserRoleMutation = useUpdateUserRoleMutation();
  const deleteUserMutation = useDeleteUserMutation();
  const [isHidden, setIsHidden] = useState(true);
  const snackbar = useSnackbar();
  const theme = useTheme();
  const buttonStyle = buttonSize(theme);
  const buttonDisplay: SxProps = {
    visibility: isHidden ? 'hidden' : 'visible',
  };

  const [showDeleteModal, hideDeleteModal] = useModal(() => (
    <DeleteDialog
      onConfirm={async () => {
        try {
          await Promise.all([deleteUserMutation.mutateAsync(user.uid)]);
          snackbar.enqueueSnackbar('User deleted successfully', {
            variant: 'success',
          });
          hideDeleteModal();
        } catch {
          snackbar.enqueueSnackbar('Failed to delete user', {
            variant: 'error',
          });
        }
      }}
      onCancel={hideDeleteModal}
      itemName={'user'}
    />
  ));

  const [inEditRole, setEditRole] = useState(false);
  const onChangeRole = async (value: RoleOption) => {
    try {
      await updateUserRoleMutation.mutateAsync({
        userId: user.uid,
        role: value.id,
      });
      snackbar.enqueueSnackbar('User role updated succesfully', {
        variant: 'success',
      });
    } catch {
      snackbar.enqueueSnackbar('Failed to update user role', {
        variant: 'error',
      });
    }
    setEditRole(false);
  };

  const selectedValue =
    roleOptions.find((role) => role.id === user.role) ?? roleOptions[0];

  return (
    <Grid
      container
      pl={2}
      pr={5}
      height={theme.spacing(6)}
      alignItems="center"
      sx={{
        borderTop: `1px solid ${theme.palette.divider}`,
      }}
      onMouseOver={() => setIsHidden(false)}
      onMouseOut={() => setIsHidden(true)}>
      <Grid item xs={12} md={4}>
        <Typography variant="body1">{user.displayName}</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="body1">{user.email}</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <ClickAwayListener onClickAway={() => setEditRole(false)}>
          {inEditRole ? (
            <Autocomplete
              size="small"
              defaultValue={selectedValue}
              options={roleOptions}
              PopperComponent={(props) => (
                <ClickAwayPopper
                  onClickAway={() => setEditRole(false)}
                  {...props}
                />
              )}
              autoComplete={true}
              onChange={(event, value) => {
                if (value) {
                  onChangeRole(value);
                }
              }}
              clearOnEscape
              openOnFocus
              sx={{ width: theme.spacing(16) }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  size="small"
                  sx={{ width: '100%' }}
                />
              )}
            />
          ) : (
            <Typography variant="body1" onClick={() => setEditRole(true)}>
              {selectedValue.label}
            </Typography>
          )}
        </ClickAwayListener>
      </Grid>
      <Grid item xs={2} md={1}>
        <Button
          sx={{
            ...buttonStyle,
            ...buttonDisplay,
          }}
          onClick={(event) => {
            event.stopPropagation();
            showDeleteModal();
          }}>
          <Delete color="primary" />
        </Button>
      </Grid>
    </Grid>
  );
};
