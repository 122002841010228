import { Add } from '@mui/icons-material';
import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import { Loading } from 'components/atoms/Loading';
import { DocumentLinkForm } from 'components/forms/DocumentLinkForm';
import { DocumentLinkAccordion } from 'components/molecules/DocumentLinkAccordion';
import { MainCard } from 'components/molecules/MainCard';
import { useCreateDocumentLinkMutation } from 'hooks/useCreateDocumentLinkMutation';
import { useSnackbar } from 'notistack';
import { DocumentsService } from 'openapi';
import { useAuth } from 'providers/AuthProvider';
import { useModal } from 'react-modal-hook';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { DocumentAccordion } from 'components/molecules/DocumentAccordion';
import { DocumentUploadForm } from 'components/forms/DocumentUploadForm';
import {
  useDocumentLinkFolders,
  useDocumentLinksQuery,
} from 'hooks/useDocumentLinkQuery';

const defaultDocumentLink = {
  id: 0,
  name: '',
  url: '',
};

export const Documents: React.FC = () => {
  const snackbar = useSnackbar();
  const { isAdmin } = useAuth();

  const { isLoading: isLoadingDocuments, data: documents } = useQuery(
    ['documents'],
    () => DocumentsService.documentsServiceGetDocuments(),
  );

  const { data: metadata } = useQuery(
    ['documentMetadata'],
    () => DocumentsService.documentsServiceGetDocumentMetadata(),
    {
      enabled: !!documents,
    },
  );

  const directories = useMemo(() => {
    if (!documents) {
      return new Array<string>();
    }
    const uniqueDirectories = new Set<string>();
    documents.forEach((item) => uniqueDirectories.add(item.directory));
    return Array.from(uniqueDirectories);
  }, [documents]);

  const { isLoading, data: externalLinks } = useDocumentLinksQuery();

  const linkFolders = useDocumentLinkFolders();

  const createDocumentLinkMutation = useCreateDocumentLinkMutation();
  const [showCreateModal, hideCreateModal] = useModal(() => (
    <Dialog
      open={true}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}>
      <Typography variant="h2" textAlign="center" py={2}>
        Create document link
      </Typography>
      <Grid container p={4}>
        <DocumentLinkForm
          mode="create"
          documentLink={defaultDocumentLink}
          onSubmit={async (documentLink) => {
            try {
              await createDocumentLinkMutation.mutateAsync(documentLink);
              snackbar.enqueueSnackbar('Document link created successfully', {
                variant: 'success',
              });
              hideCreateModal();
            } catch {
              snackbar.enqueueSnackbar('Failed to create document link', {
                variant: 'error',
              });
            }
          }}
          onCancel={hideCreateModal}
        />
      </Grid>
    </Dialog>
  ));

  const [showUploadModal, hideUploadModal] = useModal(
    () => (
      <DocumentUploadForm directories={directories} onClose={hideUploadModal} />
    ),
    [directories],
  );

  return (
    <Grid item xs={12}>
      <Typography variant="h1">Documents</Typography>
      <Box m={4} />
      <MainCard>
        {isAdmin && (
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end">
            <Button variant="contained" size="small" onClick={showUploadModal}>
              <Add />
              New
            </Button>
          </Box>
        )}
        {documents ? (
          directories.map((dir) => (
            <Box key={dir} sx={{ my: 2 }}>
              <Box pl={3} pt={2} pb={1}>
                <Typography variant="h2">
                  {dir === '/' ? 'General' : dir}
                </Typography>
              </Box>
              {documents
                .filter((doc) => doc.directory === dir)
                .map((item) => {
                  const itemMeta = metadata?.find(
                    (meta) => meta.fullPath === item.fullPath,
                  );
                  return (
                    <DocumentAccordion
                      key={item.fullPath}
                      document={item}
                      metadata={itemMeta}
                    />
                  );
                })}
            </Box>
          ))
        ) : isLoadingDocuments ? (
          <Loading />
        ) : (
          'Not found'
        )}
      </MainCard>
      <Box m={6} />
      <Typography variant="h1">Links</Typography>
      <Box m={4} />
      <MainCard>
        {isAdmin && (
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end">
            <Button variant="contained" size="small" onClick={showCreateModal}>
              <Add />
              New
            </Button>
          </Box>
        )}
        {externalLinks ? (
          linkFolders.map((dir) => (
            <Box key={dir} sx={{ my: 2 }}>
              <Box pl={3} pt={2} pb={1}>
                <Typography variant="h2">
                  {dir === '/' ? 'General' : dir}
                </Typography>
              </Box>
              {externalLinks
                .filter((doc) => (doc.folder ?? '/') === dir)
                .map((documentLink) => (
                  <DocumentLinkAccordion
                    key={documentLink.id}
                    documentLink={documentLink}
                  />
                ))}
            </Box>
          ))
        ) : isLoading ? (
          <Loading />
        ) : (
          'No document links are available.'
        )}
      </MainCard>
      <Box m={4} />
    </Grid>
  );
};
