import { ScheduleService } from 'openapi';
import { useQuery } from 'react-query';

export const usePublishDateQuery = (periodMonthYear: Date) => {
  const periodYear = periodMonthYear.getFullYear();
  const periodMonth = periodMonthYear.getMonth() + 1;
  return useQuery(['publishDate', periodYear, periodMonth], () =>
    ScheduleService.scheduleServiceGetPublishDate(periodYear, periodMonth),
  );
};
