import {
  addDays,
  addWeeks,
  endOfMonth,
  formatISO,
  isMonday,
  isSunday,
  nextSunday,
  previousMonday,
  startOfMonth,
} from 'date-fns';

export const dateToString = (date: Date) => {
  return formatISO(date, { representation: 'date' });
};

export const monthLimits = (date: Date) => {
  const firstOfMonth = startOfMonth(date);
  const lastOfMonth = endOfMonth(date);
  return [firstOfMonth, lastOfMonth];
};

export const weekSpan = (date: Date) => {
  const monday = isMonday(date) ? date : previousMonday(date);
  const fromDate = monday;
  const toDate = addDays(monday, 6);

  return [fromDate, toDate];
};

export const monthSpan = (date: Date) => {
  const firstOfMonth = startOfMonth(date);
  const lastOfMonth = endOfMonth(date);
  const fromDate = isMonday(firstOfMonth)
    ? firstOfMonth
    : previousMonday(firstOfMonth);
  const toDate = isSunday(lastOfMonth) ? lastOfMonth : nextSunday(lastOfMonth);

  return [fromDate, toDate];
};

export const monthSpanWeeks = (date: Date) => {
  const [fromDate, toDate] = monthSpan(date);
  const weekSpans = [];
  for (let date = fromDate; date < toDate; date = addWeeks(date, 1)) {
    weekSpans.push(weekSpan(date));
  }
  return weekSpans;
};

export const convertDateToUTC = (date: Date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );

export const convertDateFromUTC = (date: Date) => {
  const newDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000,
  );
  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();
  newDate.setHours(hours - offset);

  return newDate;
};

export const AssignmentHistoryStart = new Date(2008, 0, 1);
