import { SxProps, Theme } from '@mui/material';

export const buttonSize = (theme: Theme) => {
  const buttonSize: SxProps = {
    maxWidth: theme.spacing(4),
    minWidth: theme.spacing(4),
    maxHeight: theme.spacing(4),
    minHeight: theme.spacing(4),
  };
  return buttonSize;
};
