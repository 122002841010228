import { Autocomplete, TextField } from '@mui/material';
import { useRef } from 'react';

export type FolderOption = {
  name: string;
  display: string;
  isNew?: boolean;
};

export type SelectedFolder = FolderOption | string | null;

export interface FolderAutocompleteProps {
  folderOptions: Array<FolderOption>;
  selected: SelectedFolder;
  setSelected: (selected: SelectedFolder) => void;
}

export const FolderAutocomplete: React.FC<FolderAutocompleteProps> = ({
  folderOptions,
  selected,
  setSelected,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Autocomplete
      options={folderOptions}
      value={selected}
      onChange={(event, value) => {
        if (typeof value === 'string') {
          setSelected(value);
        } else if (value !== null) {
          const option = folderOptions.find(
            (option) => option.display === value?.display,
          );
          setSelected(option ?? null);
        } else {
          setSelected(null);
        }
      }}
      onBlur={() => {
        if (inputRef.current?.value) {
          const value = inputRef.current?.value;
          if (!folderOptions.find((option) => option.display === value)) {
            setSelected(value);
          }
        }
      }}
      freeSolo
      selectOnFocus
      ListboxProps={{ style: { maxHeight: '10rem' } }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? `${option} (to be added)` : option.display
      }
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          inputRef={inputRef}
          size="small"
          sx={{ width: '100%' }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.name}>
            {option.display}
          </li>
        );
      }}
    />
  );
};
