import { useMutation, useQueryClient } from 'react-query';

import { NewAssignment, ScheduleService } from 'openapi';

export const useNewAssignmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (assignment: NewAssignment) => {
      return ScheduleService.scheduleServiceCreateAssignment(assignment);
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['schedule'] });
      queryClient.refetchQueries({ queryKey: ['holidaysWithShifts'] });
    },
  });
};
