import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery,
} from 'react-query';

export const useDownloadQuery = (
  queryKey: QueryKey,
  queryFn: QueryFunction<Blob>,
  options?: Omit<
    UseQueryOptions<Blob, Error, Blob, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) => {
  const { data, refetch } = useQuery(queryKey, queryFn, {
    ...options,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return {
    data,
    download: refetch,
  };
};
