import { Download } from '@mui/icons-material';
import { Box, Button, ButtonProps, SxProps } from '@mui/material';
import { useEffect, useRef } from 'react';

type DownloadButtonProps = ButtonProps & {
  filename: string;
  caption?: string;
  sx?: SxProps;
  data: Blob | undefined;
  download: () => void;
};

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  filename,
  caption,
  sx,
  data,
  download,
  ...buttonProps
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (data && linkRef.current && !linkRef.current.href) {
      linkRef.current.href = URL.createObjectURL(data);
      linkRef.current.click();
    }
  }, [data]);

  const downloadOrClick = () => {
    if (linkRef?.current?.href) {
      linkRef.current.click();
    } else {
      download();
    }
  };

  return (
    <>
      <a
        ref={linkRef}
        href={undefined}
        download={filename}
        style={{ display: 'none' }}>
        Download
      </a>
      <Button {...buttonProps} onClick={downloadOrClick} sx={sx}>
        {caption}
        {caption && <Box px={1} />}
        <Download />
      </Button>
    </>
  );
};
