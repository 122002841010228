import { Assignment } from 'openapi';
import { createContext, ReactNode, useContext, useState } from 'react';

type EditState = {
  inEdit: boolean;
  rect?: DOMRect;
  transform?: string;
  date?: string;
  assignment?: Assignment;
};

export interface PartnerSelectOption {
  label: string;
  id: number;
  group: string;
}

export type PartnerSelectContextType = EditState & {
  startSelect: (
    elementClicked: Element,
    date: string,
    assignment?: Assignment,
  ) => void;
  cancelSelect: () => void;
  finishSelect: (value: PartnerSelectOption | null) => void;
  getPartnersAvailable: (date: string) => Array<PartnerSelectOption>;
};

const defaultStartSelect = () => {
  throw new Error('No provider was found');
};

const defaultCancelSelect = () => {
  throw new Error('No provider was found');
};

const defaultFinishSelect = () => {
  throw new Error('No provider was found');
};

const defaultGetPartnersAvailable = () => {
  throw new Error('No provider was found');
};

const PartnerSelectContext = createContext<PartnerSelectContextType>({
  inEdit: false,
  startSelect: defaultStartSelect,
  cancelSelect: defaultCancelSelect,
  finishSelect: defaultFinishSelect,
  getPartnersAvailable: defaultGetPartnersAvailable,
});

export interface PartnerSelectProviderProps {
  getPartnersAvailable: (date: string) => Array<PartnerSelectOption>;
  finishSelect: (
    value: PartnerSelectOption | null,
    date: string,
    assignment?: Assignment,
  ) => void;
  children: ReactNode;
}

export const PartnerSelectProvider = ({
  getPartnersAvailable,
  finishSelect,
  children,
}: PartnerSelectProviderProps) => {
  const [editState, setEditState] = useState<EditState>({
    inEdit: false,
  });

  return (
    <PartnerSelectContext.Provider
      value={{
        ...editState,
        startSelect: (e: Element, date: string, assignment?: Assignment) => {
          const rect = e.getBoundingClientRect();
          let transform = '';
          for (; e.parentElement && !transform; e = e.parentElement) {
            const compStyle2 = window.getComputedStyle(e.parentElement);
            if (compStyle2.transform !== 'none')
              transform = compStyle2.transform;
          }
          setEditState({
            inEdit: true,
            rect,
            transform,
            date,
            assignment,
          });
        },
        cancelSelect: () => {
          setEditState({
            inEdit: false,
          });
        },
        finishSelect: (value) => {
          if (editState.inEdit && editState.date) {
            finishSelect(value, editState.date, editState.assignment);
          }
          setEditState({
            inEdit: false,
          });
        },
        getPartnersAvailable,
      }}>
      {children}
    </PartnerSelectContext.Provider>
  );
};

export const usePartnerSelect = () => useContext(PartnerSelectContext);
