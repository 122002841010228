import { useMutation, useQueryClient } from 'react-query';

import { DocumentsService, DocumentLink } from 'openapi';

export const useUpdateDocumentLinkMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (documentLink: DocumentLink) => {
      return DocumentsService.documentsServiceUpdateDocumentLink(
        documentLink.id,
        documentLink,
      );
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['documentLinks'] });
    },
  });
};
