import { createContext, ReactNode, useContext } from 'react';

export type MenuContextType = {
  setMenu: (
    anchor: HTMLElement | undefined,
    menu: ReactNode | undefined,
  ) => void;
  menuName?: string;
  menuAnchor?: HTMLElement;
  appbarMenu?: ReactNode;
};

const defaultSetMenu = () => {
  throw new Error('No provider was found');
};

const MenuContext = createContext<MenuContextType>({ setMenu: defaultSetMenu });

export const useMenu = () => useContext(MenuContext);
export const MenuProvider = MenuContext.Provider;
