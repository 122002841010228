import { Menu } from '@mui/material';
import { useAuth } from 'providers/AuthProvider';
import { useMenu } from 'providers/MenuProvider';

import { MenuItemDescriptor } from 'types/appbar';
import { AppbarMenuItem } from './AppbarMenuItem';

export interface AppbarMenuProps {
  menuItems: Array<MenuItemDescriptor>;
}

export const AppbarMenu: React.FC<AppbarMenuProps> = ({ menuItems }) => {
  const { menuAnchor, setMenu } = useMenu();

  const { isAdmin } = useAuth();

  const handleClose = () => {
    setMenu(undefined, undefined);
  };

  return (
    <Menu
      open={Boolean(menuAnchor)}
      anchorEl={menuAnchor}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      {menuItems.map(
        (item) =>
          (!item.adminOnly || isAdmin) && (
            <AppbarMenuItem
              key={item.name}
              onClick={item.action}
              onClose={handleClose}>
              {item.content}
            </AppbarMenuItem>
          ),
      )}
    </Menu>
  );
};
