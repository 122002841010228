import {
  Box,
  Button,
  Grid,
  Theme,
  Typography,
  lighten,
  useTheme,
} from '@mui/material';
import { BasicCell, InvisibleCell } from 'components/atoms/CompReportCells';
import { Loading } from 'components/atoms/Loading';
import { MainCard } from 'components/molecules/MainCard';
import { PeriodHeader } from 'components/molecules/PeriodHeader';
import { useShiftsQuery } from 'hooks/useShiftsQuery';
import { ReportsService } from 'openapi';
import { Fragment, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { SchedulePeriod } from 'util/enum';
import { nbsp } from 'util/unicode';
import { ConfigParnterShifts } from './ConfigureShifts';
import { useModal } from 'react-modal-hook';

type ShiftDiscovered = {
  id: number;
  symbol: string;
};

const Quarters = [1, 2, 3, 4];
const colorQuarter = (theme: Theme, quarter: number) => {
  const colorMap = [
    theme.palette.primary,
    theme.palette.primary,
    theme.palette.secondary,
    theme.palette.orange,
    theme.palette.yellow,
  ];
  return lighten(colorMap[quarter].light, 0.6);
};

export const PartnerShiftsReport: React.FC = () => {
  const theme = useTheme();

  const [reportDate, setReportDate] = useState<Date>(new Date());
  const reportYear = reportDate.getFullYear();

  const { data: partnerShiftsReport, isLoading } = useQuery(
    ['partnerShiftsReport', reportYear],
    async () => ReportsService.reportsServiceGetShiftTotalsReport(reportYear),
  );
  const { data: shiftData } = useShiftsQuery();
  const { data: shiftsConfig } = useQuery(['partnerShiftsConfig'], () =>
    ReportsService.reportsServiceGetShiftTotalsConfig(),
  );

  const shiftsInUse = useMemo(() => {
    if (!partnerShiftsReport || !shiftData) {
      return [];
    }
    const shiftsDiscovered = new Array<ShiftDiscovered>();
    partnerShiftsReport.partners.forEach((partner) => {
      partner.shifts.forEach((shift) => {
        if (!shiftsDiscovered.find((entry) => entry.id === shift.shiftId)) {
          shiftsDiscovered.push({ id: shift.shiftId, symbol: shift.shift });
        }
      });
    });
    const shiftsInUse = shiftsDiscovered
      .filter((a) => {
        const shiftA = shiftData.find((s) => s.id === a.id);
        if (!shiftA) {
          return false;
        }
        return shiftsConfig?.displayedShifts.includes(a.id);
      })
      .sort((a, b) => {
        const shiftA = shiftData.find((s) => s.id === a.id);
        const shiftB = shiftData.find((s) => s.id === b.id);
        if (!shiftA || !shiftB) {
          return 0;
        }
        return shiftA.rank - shiftB.rank;
      });
    return shiftsInUse;
  }, [partnerShiftsReport, shiftData, shiftsConfig]);

  const widthWithDays = Math.max(4 * 4 + 16 * shiftsInUse.length, 40);
  const widthSpec = theme.spacing(widthWithDays);

  const [showConfigShiftsModal, hideConfigShiftsModal] = useModal(() => (
    <ConfigParnterShifts hideModal={hideConfigShiftsModal} />
  ));

  return (
    <Grid item xs={12}>
      <Typography variant="h1">Partner Shifts Report</Typography>
      <Box m={4} />
      <Grid container display="flex" justifyContent="right">
        <Grid item xs={8} md={1}>
          <Button
            variant="contained"
            size="small"
            onClick={showConfigShiftsModal}>
            Configure
          </Button>
        </Grid>
      </Grid>
      <Box m={2} />
      <MainCard sx={{ minWidth: widthSpec }}>
        <Grid container justifyContent="center">
          <Grid item>
            <Box minWidth={100} />
          </Grid>
          <Grid item alignSelf="center" marginRight="auto" marginLeft="auto">
            <PeriodHeader
              period={SchedulePeriod.Annually}
              startDate={reportDate}
              onSelectDate={setReportDate}
            />
          </Grid>
          {/* <Grid item width={100} display="flex" justifyContent="right">
            <Button onClick={handlePrint}>
              <LocalPrintshop />
            </Button>
          </Grid> */}
        </Grid>
        {partnerShiftsReport ? (
          <Grid>
            <Grid item display="flex" alignItems="top">
              <InvisibleCell width={2} />
              <InvisibleCell width={0.5} />
              {shiftsInUse.map((shift) => (
                <Fragment key={shift.id}>
                  <BasicCell key={shift.id} width={4}>
                    {shift.symbol}
                  </BasicCell>
                  <InvisibleCell width={0.5} />
                </Fragment>
              ))}
            </Grid>
            <Grid item display="flex" alignItems="top">
              <InvisibleCell width={2} />
              <InvisibleCell width={0.5} />
              {shiftsInUse.map((shift) => (
                <Fragment key={shift.id}>
                  {Quarters.map((quarter) => (
                    <BasicCell key={quarter}>Q{quarter}</BasicCell>
                  ))}
                  <InvisibleCell width={0.5} />
                </Fragment>
              ))}
            </Grid>
            {partnerShiftsReport.partners
              .sort(
                (a, b) =>
                  a.partnerNickname?.localeCompare(b?.partnerNickname ?? '') ??
                  0,
              )
              .map((partner) => (
                <Grid
                  key={partner.partnerId}
                  item
                  display="flex"
                  alignItems="top">
                  <BasicCell width={2} border={false}>
                    {partner.partnerNickname}
                  </BasicCell>
                  <InvisibleCell width={0.5} />
                  {shiftsInUse.map((shift) => {
                    const partnerShift = partner.shifts.find(
                      (s) => s.shiftId === shift.id,
                    );
                    return (
                      <Fragment key={shift.id}>
                        {Quarters.map((quarter) => {
                          const partnerQuarter = partnerShift?.quarters.find(
                            (q) => q.quarter === quarter,
                          );
                          return (
                            <BasicCell
                              key={quarter}
                              background={colorQuarter(theme, quarter)}>
                              {partnerQuarter?.count ?? nbsp}
                            </BasicCell>
                          );
                        })}
                        <InvisibleCell width={0.5} />
                      </Fragment>
                    );
                  })}
                </Grid>
              ))}
          </Grid>
        ) : isLoading ? (
          <Loading />
        ) : (
          'Partner Shifts Report not available.'
        )}
      </MainCard>
    </Grid>
  );
};
