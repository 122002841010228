import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'react-modal-hook';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import { OpenAPI } from 'openapi';
import { FirebaseProvider } from 'providers/FirebaseProvider';
import { AuthProvider } from 'providers/AuthProvider';
import { QueryProvider } from 'providers/QueryProvider';
import { EditStateProvider } from 'providers/EditStateProvider';

import 'assets/scss/print.scss';
import ThemeCustomization from 'theme';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

// not exactly elegant -- this object needs to be initialized before program
// execution but the file where this is located gets overwritten by codegen
OpenAPI.BASE =
  `${process.env.REACT_APP_BASE_URL}/api` || 'http://localhost:3001/api';

root.render(
  <StrictMode>
    <ThemeCustomization>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FirebaseProvider>
          <QueryProvider>
            <AuthProvider>
              <SnackbarProvider>
                <ModalProvider>
                  <BrowserRouter>
                    <EditStateProvider>
                      <App />
                    </EditStateProvider>
                  </BrowserRouter>
                </ModalProvider>
              </SnackbarProvider>
            </AuthProvider>
          </QueryProvider>
        </FirebaseProvider>
      </LocalizationProvider>
    </ThemeCustomization>
  </StrictMode>,
);
