import { ClickAwayListener, Popper, PopperProps } from '@mui/material';
import React from 'react';

export interface ClickAwayPopperProps extends PopperProps {
  onClickAway: (event: MouseEvent | TouchEvent) => void;
}

export const ClickAwayPopper: React.FC<ClickAwayPopperProps> = ({
  onClickAway,
  children,
  ...popperProps
}) => {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper {...popperProps}>{children}</Popper>
    </ClickAwayListener>
  );
};
