import React, { useRef } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import {
  PartnerSelectOption,
  usePartnerSelect,
} from 'providers/PartnerSelectProvider';

export const None: PartnerSelectOption = {
  label: '(none)',
  id: -1,
  group: '',
};

export const Same: PartnerSelectOption = {
  label: '(same)',
  id: -2,
  group: '',
};

export interface PartnerSelectProps {
  value: PartnerSelectOption | null;
  useWithinDialog?: boolean;
}

export const PartnerSelect: React.FC<PartnerSelectProps> = ({
  value,
  useWithinDialog,
}) => {
  const partnerSelect = usePartnerSelect();
  const inputRef = useRef<HTMLInputElement>(null);

  const topOffset = useWithinDialog ? 0 : window.pageYOffset;
  const leftOffset = useWithinDialog ? 0 : window.pageXOffset;

  return partnerSelect.inEdit ? (
    <Autocomplete
      options={
        partnerSelect.date
          ? partnerSelect.getPartnersAvailable(partnerSelect.date)
          : []
      }
      groupBy={(partner) => partner.group}
      value={value}
      autoComplete={true}
      clearOnEscape
      openOnFocus
      onKeyDown={(event) => {
        if (event.key === 'Escape' && inputRef.current?.value === '') {
          partnerSelect.cancelSelect();
        }
      }}
      onBlur={() => {
        partnerSelect.cancelSelect();
      }}
      onChange={(event, value) => {
        if (value !== undefined) {
          partnerSelect.finishSelect(value);
        }
      }}
      sx={{
        zIndex: 2,
        position: 'absolute',
        top: (partnerSelect.rect?.top ?? 0) + topOffset,
        left: (partnerSelect.rect?.left ?? 0) + leftOffset,
        height: partnerSelect.rect?.height,
        width: partnerSelect.rect?.width,
      }}
      ListboxProps={{ style: { maxHeight: '10rem' } }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          inputRef={inputRef}
          size="small"
          sx={{ width: '100%' }}
        />
      )}
    />
  ) : null;
};
