import { DocumentsService } from 'openapi';
import { useMutation, useQueryClient } from 'react-query';

interface UploadDocumentParams {
  dirName: string;
  doc: Blob;
}

export const useUploadDocumentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: UploadDocumentParams) => {
      return DocumentsService.documentsServiceUploadDocument(params);
    },
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ['documents'] });
      queryClient.refetchQueries({ queryKey: ['documentMetadata'] });
    },
  });
};
