import { useState } from 'react';
import { Delete, Edit, Launch } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  Button,
  Dialog,
  Grid,
  Link,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { useDeleteDocmentLinkMutation } from 'hooks/useDeleteDocumentLinkMutation';
import { useUpdateDocumentLinkMutation } from 'hooks/useUpdateDocumentLinkMutation';
import { useAuth } from 'providers/AuthProvider';
import { DeleteDialog } from './DeleteDialog';
import { useModal } from 'react-modal-hook';
import { DocumentLink } from 'openapi';
import { DocumentLinkForm } from 'components/forms/DocumentLinkForm';
import { buttonSize } from 'util/styling';

export interface DocumentLinkAccordionProps {
  documentLink: DocumentLink;
}

export const DocumentLinkAccordion: React.FC<DocumentLinkAccordionProps> = ({
  documentLink,
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const snackbar = useSnackbar();
  const { isAdmin } = useAuth();
  const theme = useTheme();
  const buttonStyle = buttonSize(theme);
  const buttonDisplay: SxProps = {
    visibility: isHidden ? 'hidden' : 'visible',
  };

  const deleteDocumentLinkMutation = useDeleteDocmentLinkMutation();
  const [showDeleteModal, hideDeleteModal] = useModal(() => (
    <DeleteDialog
      onConfirm={async () => {
        try {
          await deleteDocumentLinkMutation.mutateAsync(documentLink.id);
          snackbar.enqueueSnackbar('Document link deleted successfully', {
            variant: 'success',
          });
          hideDeleteModal();
        } catch {
          snackbar.enqueueSnackbar('Failed to delete document link', {
            variant: 'error',
          });
        }
      }}
      onCancel={hideDeleteModal}
      itemName="document link"
    />
  ));

  const documentLinkMutation = useUpdateDocumentLinkMutation();
  const [showEditModal, hideEditModal] = useModal(() => (
    <Dialog
      open={true}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}>
      <Typography variant="h2" textAlign="center" py={2}>
        Edit document link
      </Typography>
      <Grid container p={4}>
        <DocumentLinkForm
          mode="update"
          documentLink={documentLink}
          onSubmit={async (documentLink) => {
            try {
              await documentLinkMutation.mutateAsync(documentLink);
              snackbar.enqueueSnackbar('Document link update successfully', {
                variant: 'success',
              });
              hideEditModal();
            } catch {
              snackbar.enqueueSnackbar('Failed to update document link', {
                variant: 'error',
              });
            }
          }}
          onCancel={hideEditModal}
        />
      </Grid>
    </Dialog>
  ));

  return (
    <Accordion
      disableGutters={true}
      sx={{
        '&:before': {
          display: 'none',
        },
      }}>
      <AccordionSummary
        onMouseOver={() => setIsHidden(false)}
        onMouseOut={() => setIsHidden(true)}
        sx={{
          minHeight: theme.spacing(4),
          maxHeight: theme.spacing(4),
        }}>
        <Grid key="title" container alignItems="center" sx={{ margin: 1 }}>
          <Launch fontSize="small" sx={{ marginRight: 2 }} />
          <Link href={documentLink.url} target="_blank">
            {documentLink.name}
          </Link>

          {isAdmin && (
            <Grid item xs={2} md={1} pl={2}>
              <Button
                sx={{
                  ...buttonStyle,
                  ...buttonDisplay,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  showEditModal();
                }}>
                <Edit color="primary" />
              </Button>
              <Button
                sx={{
                  ...buttonStyle,
                  ...buttonDisplay,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  showDeleteModal();
                }}>
                <Delete color="primary" />
              </Button>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
    </Accordion>
  );
};
