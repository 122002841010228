import { MenuItem, useTheme } from '@mui/material';

export interface AppbarMenuItemProps {
  onClick: () => void;
  onClose: () => void;
  children: React.ReactNode;
}

export const AppbarMenuItem: React.FC<AppbarMenuItemProps> = ({
  onClick,
  onClose,
  children,
}) => {
  const theme = useTheme();
  const handleClick = () => {
    onClick();
    onClose();
  };

  return (
    <MenuItem sx={{ width: theme.spacing(20) }} onClick={handleClick}>
      {children}
    </MenuItem>
  );
};
