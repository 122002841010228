import { Add } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  FormControl,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import { useShiftsQuery } from 'hooks/useShiftsQuery';
import { Shift } from 'openapi';
import { useState, useEffect, useRef } from 'react';
import { buttonSize } from 'util/styling';

export interface ShiftSelectAddProps {
  shiftsToExclude: Array<number>;
  onSelectShift: (shift: Shift) => void;
}

export const ShiftSelectAdd: React.FC<ShiftSelectAddProps> = ({
  shiftsToExclude,
  onSelectShift,
}) => {
  const theme = useTheme();

  const { data: shifts } = useShiftsQuery();

  const [shiftsAvailable, setShiftsAvailable] = useState<Shift[]>([]);
  const [selectedShift, setSelectedShift] = useState<Shift | null>(null);

  const buttonStyle = buttonSize(theme);

  useEffect(
    () => {
      const shiftsAvailable =
        shiftsToExclude && shifts
          ? shifts
              .filter((shift) => !shiftsToExclude.includes(shift.id))
              .sort((a, b) => a.rank - b.rank)
          : [];
      setShiftsAvailable(shiftsAvailable);

      if (!selectedShift && shiftsAvailable.length > 0) {
        setSelectedShift(shiftsAvailable[0]);
      }
    },
    // ignore selectShift because that creates infinite loop
    [shifts, shiftsToExclude],
  );

  const shiftSelections = shiftsAvailable.map((shift) => ({
    id: shift.id,
    label: shift.description,
  }));
  const selectedShiftValue =
    shiftSelections.find(
      (shiftSelection) => shiftSelection.id === selectedShift?.id,
    ) ?? null;

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Stack direction="row" spacing={2}>
      <FormControl>
        <Autocomplete
          options={shiftSelections}
          value={selectedShiftValue}
          autoComplete={true}
          onChange={(event, value) => {
            if (value !== undefined) {
              const selected =
                shifts?.find((shift) => shift.id === value?.id) ?? null;
              setSelectedShift(selected);
            }
          }}
          clearOnEscape
          ListboxProps={{
            style: { maxHeight: '10rem' },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              inputRef={inputRef}
              size="small"
              sx={{ minWidth: theme.spacing(18) }}
            />
          )}
          sx={{ display: 'inline' }}
        />
      </FormControl>
      <Button
        sx={{
          ...buttonStyle,
        }}
        onClick={(event) => {
          event.stopPropagation();
          if (selectedShift) {
            onSelectShift(selectedShift);
          }
        }}>
        <Add color="primary" />
      </Button>
    </Stack>
  );
};
